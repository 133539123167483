import React, { useEffect, useState } from 'react';
import { Card, Table, Pagination, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import attributeReducer, { initialState as attributeInitialState } from 'pages/Attribute/reducer';
import attributeSaga from 'pages/Attribute/saga';
import _ from 'lodash';
import AddProductDrawer from 'components/AddProductDrawer';
// import ConfirmModal from 'components/ConfirmModal';
import categorySaga from 'pages/Category/saga';
import categoryReducer, { initialState as categoryInitialState } from 'pages/Category/reducer';
import { ProductTableConfig } from './ProductTableConfig';
import { listProduct, toggleDrawer, toggleDeleteModal } from './action';
// import { listCategory } from 'containers/Category/action';

function Product() {
  useInjectReducer({ key: 'product', reducer });
  useInjectSaga({ key: 'product', saga });
  useInjectReducer({ key: 'category', reducer: categoryReducer });
  useInjectSaga({ key: 'category', saga: categorySaga });
  useInjectReducer({ key: 'attribute', reducer: attributeReducer });
  useInjectSaga({ key: 'attribute', saga: attributeSaga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const productState = useSelector((state) => state.product || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = ProductTableConfig(
      handleSearch,
      handleReset,
      onDelete,
      editProduct,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listProduct({ page: 1, limit: 10 }));
  }, []);

  const onDelete = (item) => { };

  const resetPagination = () => setCurrentPage(1);

  const editProduct = (product) => {
    setSelectedProduct(product);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    let filter = { page, limit: pageSize };
    setCurrentPage(page);
    if ((searchedColumn, searchText)) {
      filter = { ...filter, [searchedColumn]: searchText };
    }
    dispatch(listProduct(filter));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys.length <= 0) {
      message.error('Please select to search');
      return;
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    dispatch(listProduct({ page: 1, limit: 10, [dataIndex]: selectedKeys[0] }));
    resetPagination();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(null);
    setSearchedColumn(null);
    dispatch(listProduct({ page: 1, limit: 10 }));
    resetPagination();
  };
  return (
    <>
      <Card
        title="Product Configuration"
        extra={
          <AddProductDrawer
            initialState={initialState}
            categoryInitialState={categoryInitialState}
            selectedProduct={selectedProduct}
            resetPagination={resetPagination}
            editProduct={editProduct}
            roleAddResponsibility={_.includes(
              authState.userResponsibilities,
              'PRODUCT_PROD_ADD_BUTTON',
            )}
          />
        }
      >
        <Table
          columns={columns}
          loading={productState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={productState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={productState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={productState.loading}
          onChange={onPageChange}
        />
      </Card>
      {/* <ConfirmModal
        title={'Delete Attribute Confirmation'}
        text={'Are you sure you want to delete this attribute ?'}
        toggle={roleState.deleteModal}
        loading={roleState.loading}
        handleDeleteModalToggle={handleDeleteModalToggle}
        onDelete={onDelete}
      /> */}
    </>
  );
}

export default Product;
