export const ADD_TESTIMONIAL = 'ADD_TESTIMONIAL';
export const ADD_TESTIMONIAL_SUCCESS = 'ADD_TESTIMONIAL_SUCCESS';
export const ADD_TESTIMONIAL_ERROR = 'ADD_TESTIMONIAL_ERROR';

export const EDIT_TESTIMONIAL = 'EDIT_TESTIMONIAL';
export const EDIT_TESTIMONIAL_SUCCESS = 'EDIT_TESTIMONIAL_SUCCESS';
export const EDIT_TESTIMONIAL_ERROR = 'EDIT_TESTIMONIAL_ERROR';

export const LIST_TESTIMONIAL = 'LIST_TESTIMONIAL';
export const LIST_TESTIMONIAL_SUCCESS = 'LIST_TESTIMONIAL_SUCCESS';
export const LIST_TESTIMONIAL_ERROR = 'LIST_TESTIMONIAL_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';
