import React, { memo, useEffect, useState } from 'react';
import { Form, Button, Drawer, Skeleton, Row, Col, Select, Input, Divider, message } from 'antd';
import { useDispatch } from 'react-redux';
import { drawerData, addOrder, addToCart as addToCartAction, clearCartList, categoryAddon } from 'pages/Orders/action';
import OrderCartItems from 'components/OrderCartItems';
// import OrderAddon from 'components/OrderAddon';
import OrderConfirmModal from 'components/OrderConfirmModal';
import _ from 'lodash';
const { Option } = Select;

function AddOrderDrawer({ resetPagination, selectedOrder, editOrder, orderState, roles }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [productForm] = Form.useForm();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [values, setValues] = useState({});
  useEffect(() => {
    if (orderState.drawer) {
      form.setFieldsValue({
        discount: 0,
        subscription_period: '1',
        invoice_status: 0,
        prorated_invoice: false,
      });
      productForm.setFieldsValue({
        customprice: 0,
        product_quantity: 1,
      });
      dispatch(drawerData());
    }
  }, [orderState.drawer]);

  if (!_.isNil(selectedOrder)) {
    let cats = orderState.data.filter(
      (cat) => !_.isEqual(cat.parentcategoryid, selectedOrder.parentcategoryid),
    );
    form.setFieldsValue({
      // parentcategoryid: selectedCategory.parentid,
      // productcatname: selectedCategory.parentcategoryname,
      selectclient: selectedOrder.selectclient,
      customprice: selectedOrder.customprice,
      client: selectedOrder.client,
      discount: selectedOrder.discount,
      duration: selectedOrder.duration,
      payment_method: selectedOrder.payment_method,
      send_invoice_email: selectedOrder.Send_invoice_email_to_client || false,
      prorated_invoice: selectedOrder.prorated_invoice,
      terms: selectedOrder.terms,
      invoice_status: _.isEqual(selectedOrder.invoice_status, 1) ? 0 : 1,
    });
  }

  const toggleOrderDrawer = (open = false) => {
    dispatch(clearCartList());
    form.resetFields();
    editOrder(null);
  };

  const saveClientOrder = () => {
    form.validateFields().then((values) => {
      const product = orderState.drawerData.products.filter((p) =>
        _.isEqual(p.productid, values.product_id),
      );
      if (orderState.cartList.length <= 0) {
        message.error('Please Select Product');
        return;
      }
      const payload = {
        client_id: values.client_id,
        product: orderState.cartList.map((ord) => ({
          product_id: ord.product_id,
          quantity: ord.quantity,
          custom_price: ord.customprice,
        })),
        invoice_status: values.invoice_status ? 1 : 2,
        payment_method: parseInt(values.payment_method),
        discount: parseInt(values.discount),
        subscription_period: parseInt(values.subscription_period),
        prorated_invoice: values.prorated_invoice,
        send_invoice_email: values.send_invoice_email || false,
      };
      values.comments && (payload['comments'] = values.comments);
      if (orderState.addonCartList.length > 0) {
        const addons = orderState.addonCartList.map((od) => ({
          addon_id: od.addon_id,
          attribute_id: od.attribute_id,
          quantity: od.quantity,
        }));
        payload['addons'] = addons;
      }
      dispatch(addOrder(payload));
      resetPagination();
    });
  };
  const onChange = (product_id) => {
    const selectedProduct = orderState.drawerData.products.filter(
      (od) => od.productid == product_id,
    );
    dispatch(categoryAddon({ category_id: selectedProduct[0].productcategoryid }));
  };
  const confirmOrder = (val, confirmOrder = false) => {
    if (confirmOrder) {
      saveClientOrder();
      setOpenConfirmModal(val);
    } else {
      form.validateFields().then((values) => {
        setOpenConfirmModal(val);
        setValues(values);
      });
    }
  };
  const addToCart = () => {
    productForm.validateFields().then((values) => {
      const productDecorator = {};
      if (isNaN(values.product_quantity) || values.product_quantity < 0) {
        message.error('Invalid quantity');
        return;
      }
      if (values.customprice && (isNaN(values.customprice) || values.customprice < 0)) {
        message.error('Invalid custom price');
        return;
      }
      const selectedProduct = orderState.cartList.filter((pr) =>
        _.isEqual(pr.product_id, values.product_id),
      );
      if (selectedProduct.length > 0) {
        message.error('Product Already added in cart');
        return;
      }
      const product = orderState.drawerData.products.filter((pr) =>
        _.isEqual(pr.productid, values.product_id),
      );
      const custom_price = parseInt(values.customprice);
      productDecorator['key'] = product[0].productid;
      productDecorator['product_id'] = product[0].productid;
      productDecorator['productname'] = product[0].productname;
      productDecorator['quantity'] = values.product_quantity;
      productDecorator['startingprice'] = product[0].startingprice;
      productDecorator['customprice'] =
        custom_price != product[0].startingprice && custom_price > 0
          ? custom_price
          : product[0].startingprice;
      dispatch(addToCartAction([productDecorator]));
      productForm.setFieldsValue({
        customprice: 0,
        product_quantity: 1,
      });
    });
  };
  const clients =
    Object.keys(orderState.drawerData).length > 0 ? orderState.drawerData.clients : [];
  const products =
    Object.keys(orderState.drawerData).length > 0 ? orderState.drawerData.products : [];
  const paymentMethods =
    Object.keys(orderState.drawerData).length > 0 ? orderState.drawerData.paymentMethods : [];
  return (
    <div>
      <OrderConfirmModal
        open={openConfirmModal}
        confirmOrder={confirmOrder}
        values={values}
        orderState={orderState}
      />
      <Button
        onClick={() => toggleOrderDrawer()}
        size="medium"
        style={{
          backgroundColor: "#00916c",
          borderColor: "#00916c",
          color: "white",
        }}
      >
        Add Orders
      </Button>
      <Drawer
        title={selectedOrder ? `Edit Order` : `Add Order`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleOrderDrawer()}
        visible={orderState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggleOrderDrawer()}
              loading={orderState.loading}
              disabled={orderState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => confirmOrder(true)}
              loading={orderState.loading}
              disabled={orderState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedOrder ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton loading={orderState.drawerLoading} active={orderState.drawerLoading}>
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="client_id"
                  label="Select Client"
                  rules={[{ required: true, message: 'Please select client' }]}
                >
                  <Select
                    placeholder="Select Client"
                    loading={orderState.loading || false}
                    disabled={orderState.loading || false}
                  >
                    {clients.map((cl) => (
                      <Option value={cl.client_id} key={cl.client_id}>
                        {cl.client_name} {cl.last_name} ({cl.client_id})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="discount" label="Discount (Optional)">
                  <Input
                    type="number"
                    placeholder="Please enter discount"
                    disabled={orderState.loading}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="subscription_period"
                  label="Subscription Period"
                  rules={[{ required: false, message: 'Please select Subscription Period' }]}
                >
                  <Select placeholder="Subscription Period" disabled={orderState.loading}>
                    <Option value="1">Monthly</Option>
                    <Option value="3">Quarterly</Option>
                    <Option value="6">Biannually</Option>
                    <Option value="12">Yearly</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="payment_method"
                  label="Payment Method"
                  rules={[{ required: true, message: 'Please enter Payment Method' }]}
                >
                  <Select placeholder="Payment Method" disabled={orderState.loading}>
                    {paymentMethods.map((pm) => (
                      <Option value={pm.payment_method_id} key={pm.payment_method_id}>
                        {pm.payment_method_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col span={24}>
                <Form.Item
                  name="invoice_status"
                  label="Invoice Status"
                  disabled={orderState.loading}
                >
                  <Select placeholder="Invoice Status" defaultValue={0}>
                    <Option value={0}>UnPaid</Option>
                    <Option value={1}>Paid</Option>
                    <Option value={2}>Deferred</Option>
                    <Option value={4}>Completed</Option>
                    <Option value={5}>Due Date Crossed</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col span={24}>
                <Form.Item
                  name="prorated_invoice"
                  label="Prorated Invoice"
                  disabled={orderState.loading}
                >
                  <Select placeholder="Prorated Invoice" defaultValue={true}>
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="comments"
                  label="Comments"
                  rules={[{ required: false, message: 'Please enter comments' }]}
                >
                  <Input.TextArea
                    placeholder="Please enter comments"
                    rows={4}
                    allowClear={true}
                    disabled={orderState.loading}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="send_invoice_email"
                  label="Send Invoice Email to Client"
                  loading={orderState.loading}
                >
                  <Select
                    placeholder="Send Invoice Email to Client"
                    defaultValue={false}
                    disabled={orderState.loading}
                  >
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Form form={productForm} layout="vertical" initialValues={{ remember: false }}>
            <Divider>Select Product</Divider>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="product_id"
                  label="Select Product"
                  rules={[{ required: true, message: 'Please select product' }]}
                >
                  <Select
                    placeholder="Select Product"
                    loading={orderState.loading}
                    disabled={orderState.loading}
                    onChange={onChange}
                  >
                    {products.map((p) => (
                      <Option value={p.productid} key={p.productid}>
                        {p.productname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="product_quantity"
                  label="Enter Product Quantity"
                  rules={[{ required: true, message: 'Please enter product quantity' }]}
                >
                  <Input type="number" style={{ width: '100%' }} disabled={orderState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="customprice" label="Custom Price">
                  <Input type="number" style={{ width: '100%' }} disabled={orderState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ textAlign: 'right' }}>
              <Col flex="1 0 25%">
                <Button
                  size="large"
                  onClick={addToCart}
                  loading={orderState.loading}
                  style={{ backgroundColor: '#32d193', borderColor: '#32d193', color: 'white' }}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
          {/* <OrderAddon
            addon={orderState.addonData}
            loading={orderState.loading}
            orderState={orderState}
          /> */}
          <Divider>Cart</Divider>
          <OrderCartItems orderState={orderState} />
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddOrderDrawer);
