import * as Constants from './constant';

export const loginUser = (payload) => {
  return {
    type: Constants.USER_LOGIN,
    payload,
  };
};

export const loginUserSuccess = (payload) => {
  return {
    type: Constants.USER_LOGIN_SUCCESS,
    payload,
  };
};

export const loginUserError = (error) => {
  return {
    type: Constants.USER_LOGIN_ERROR,
    error,
  };
};

export const authUser = () => {
  return {
    type: Constants.GET_AUTH_USER,
  };
};

export const authUserSuccess = (payload) => {
  return {
    type: Constants.GET_AUTH_USER_SUCCESS,
    payload,
  };
};

export const authUserError = (error) => {
  return {
    type: Constants.GET_AUTH_USER_ERROR,
    error,
  };
};

export const logoutUser = () => {
  return {
    type: Constants.USER_LOGOUT,
  };
};
