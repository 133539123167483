import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';
import _ from "lodash"

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const listPortfolio = async ({ limit, page, portfolio_name, isactive, isfeatured }) => {
  let portfolioApi = superagent
    .get(`${BASE_URL}/portfolio/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .query({ page })
    .query({ limit });

  if (portfolio_name) {
    portfolioApi.query({ portfolio_name });
  }
  if (!_.isNil(isactive)) {
    portfolioApi.query({ isactive });
  }
  return await portfolioApi;
};

export const addPortfolio = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/portfolio/create`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const editPortfolio = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/portfolio/edit`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};
export const deletePortfolio = async (portfolio_id) => {
  return await superagent
    .delete(`${BASE_URL}/portfolio/delete`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .send({ portfolio_id })
    .retry(2);
};
