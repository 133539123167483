import _ from 'lodash';
import * as Constant from './constant';

export const initialState = { totalCount: 0, data: [], loading: false, categories: [], drawerData: {}, selectedAddons: [], drawerLoading: false, error: false, drawer: false, deleteModal: false };

const addonReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_ADDON:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
      };
    case Constant.LIST_ADDON_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: action.payload.total,
        data: action.payload.addons,
        error: false,
      };
    case Constant.LIST_ADDON_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };
    case Constant.LIST_ADDON_CATEGORY:
      return {
        ...state,
        loading: true,
        categories: [],
        error: false,
      };
    case Constant.LIST_ADDON_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        error: false,
      };
    case Constant.LIST_ADDON_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        categories: [],
        error: true,
      };
    case Constant.DRAWER_DATA:
      return {
        ...state,
        drawerLoading: true,
        drawerData: [],
      };
    case Constant.DRAWER_DATA_SUCCESS:
      return {
        ...state,
        drawerLoading: false,
        drawerData: action.payload,
      };
    case Constant.DRAWER_DATA_ERROR:
      return {
        ...state,
        drawerLoading: false,
        drawerData: [],
      };
    case Constant.ADD_ADDON:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ADD_ADDON_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.ADD_ADDON_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.EDIT_ADDON:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.EDIT_ADDON_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.EDIT_ADDON_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.TOGGLE_DRAWER:
      return {
        ...state,
        drawer: !state.drawer,
      };
    case Constant.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !state.deleteModal,
      };
    case Constant.ADD_ADDON_ATTRIBUTE:
      return {
        ...state,
        selectedAddons: [...state.selectedAddons, ...action.payload],
      };
    case Constant.REMOVE_ADDON_ATTRIBUTE:
      const selectedAddons = state.selectedAddons.filter(
        (cl) => !_.isEqual(cl.attributeid, action.payload[0].attributeid),
      );
      return {
        ...state,
        selectedAddons,
      };
    case Constant.CLEAR_ADDON_ATTRIBUTE:
      return {
        ...state,
        selectedAddons: [],
      };
    default:
      return state;
  }
};

export default addonReducer;
