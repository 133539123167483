import { Col, Row, Spin } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
// import _ from "lodash";
// import MultiColorLineChart from "components/chart/EChart";
// import LinChart from "components/chart/LineChart";
import Statisticss from "components/DashboardStatics";
import MultiColorLineChart from "components/chart/EChart";

function Dashboard() {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  <Spin indicator={antIcon} />;

  return (
    <div>
      <div>
        <Statisticss />

        <br />
        <Row style={{ padding: "10px" }} gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <MultiColorLineChart />
          </Col>
          {/* <Col xs={24} md={12}>
            <LinChart />
          </Col> */}
        </Row>
      </div>
    </div>
  );
}

export default Dashboard;
