import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import _ from 'lodash';
import AddInvoiceSettingDrawer from 'components/AddInvoiceSettingDrawer/Index';
import { InvoiceSettingTableConfig } from './InvoiceSettingTableConfig';
import { listInvoiceSetting, toggleDrawer } from './action';

function InvoiceSetting() {
  useInjectReducer({ key: 'invoice_setting', reducer });
  useInjectSaga({ key: 'invoice_setting', saga });
  const [columns, setColumns] = useState([]);
  const [selectedInvoiceSetting, setSelectedInvoiceSetting] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const invoiceSettingState = useSelector((state) => state.invoice_setting || initialState);

  const dispatch = useDispatch();
  useEffect(() => {
    const columns = InvoiceSettingTableConfig(editInvoiceSetting);
    setColumns(columns);
    dispatch(listInvoiceSetting({ paramType: 'Invoice_Setting' }));
  }, []);

  const resetPagination = () => setCurrentPage(1);

  const editInvoiceSetting = (item) => {
    setSelectedInvoiceSetting(item);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    dispatch(listInvoiceSetting({ page, limit: pageSize }));
  };
  return (
    <>
      <Card
        title="Invoice Setting"
        extra={
          <AddInvoiceSettingDrawer
            initialState={initialState}
            selectedInvoiceSetting={selectedInvoiceSetting}
            resetPagination={resetPagination}
            editInvoiceSetting={editInvoiceSetting}
            invoiceSettingState={invoiceSettingState}
          />
        }
      >
        <Table
          columns={columns}
          loading={invoiceSettingState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={invoiceSettingState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
      </Card>
    </>
  );
}

export default InvoiceSetting;
