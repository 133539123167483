
export const ADD_DEDICATED = 'ADD_DEDICATED';
export const ADD_DEDICATED_SUCCESS = 'ADD_DEDICATED_SUCCESS';
export const ADD_DEDICATED_ERROR = 'ADD_DEDICATED_ERROR';

export const EDIT_DEDICATED = 'EDIT_DEDICATED';
export const EDIT_DEDICATED_SUCCESS = 'EDIT_DEDICATED_SUCCESS';
export const EDIT_DEDICATED_ERROR = 'EDIT_DEDICATED_ERROR';

export const LIST_DEDICATED = 'LIST_DEDICATED';
export const LIST_DEDICATED_SUCCESS = 'LIST_DEDICATED_SUCCESS';
export const LIST_DEDICATED_ERROR = 'LIST_DEDICATED_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


