import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { listFaq, addFaq, editFaq } from 'services/faq';
import { ADD_FAQ, EDIT_FAQ, LIST_FAQ, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listFaq as listFaqAction, listFaqSuccess, listFaqError, addFaqSuccess, addFaqError, editFaqSuccess, editFaqError, toggleDrawer, toggleDeleteModal } from './action';

export function* listFaqSaga(action) {
  try {
    const res = yield call(listFaq, action.payload);
    const faqData = JSON.parse(res.text);
    // console.log(faqData)

    yield put(
      listFaqSuccess({
        attributes: faqData.data.faq.map((item) => ({
          ...item,
          key: item.faqid,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listFaqError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listFaqError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}
export function* addFaqSaga(action) {
  try {
    const res = yield call(addFaq, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addFaqSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listFaqAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addFaqError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addFaqError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addFaqError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editFaqSaga(action) {
  try {
    const res = yield call(editFaq, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editFaqSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listFaqAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editFaqError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editFaqError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editFaqError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* FaqSaga() {
  yield takeLatest(LIST_FAQ, listFaqSaga);
  yield takeEvery(ADD_FAQ, addFaqSaga);
  yield takeEvery(EDIT_FAQ, editFaqSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
