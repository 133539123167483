import React, { memo } from "react";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { logoutUser } from "pages/AppLogin/action";

function DropDownMenu(props) {
  const history = useHistory();

  const logoutUser = () => {
    props.logoutUser();
    localStorage.clear();
    history.push("/");
  };
  return (
    <Menu className="account-menu" style={{ border: "1px solid #F5F3F3" }}>
      <Menu.Item key="0">
        <a href="#">
          <UserOutlined style={{ color: "#00AEEF" }} />
          Profile
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <span onClick={logoutUser}>
          <LogoutOutlined style={{ color: "red" }} />
          Logout
        </span>
      </Menu.Item>
    </Menu>
  );
}

const mapDispatchToProps = {
  logoutUser: () => logoutUser(),
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(DropDownMenu);
