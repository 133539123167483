import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { listQuote, addQuote, editQuote } from 'services/qoute';
import { ADD_QUOTE, EDIT_QUOTE, LIST_QUOTE, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listQuote as listQuoteAction, listQuoteSuccess, listQuoteError, addQuoteSuccess, addQuoteError, editQuoteSuccess, editQuoteError, toggleDrawer, toggleDeleteModal } from './action';

export function* listQuoteSaga(action) {
  try {
    const res = yield call(listQuote, action.payload);
    const quoteData = JSON.parse(res.text);
    // console.log(quoteData)
    yield put(
      listQuoteSuccess({
        total: quoteData.quotes.total,
        attributes: quoteData.quotes.quotes.map((item) => ({
          ...item,
          key: item.quote_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listQuoteError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listQuoteError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}
export function* addQuoteSaga(action) {
  try {
    const res = yield call(addQuote, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addQuoteSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listQuoteAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addQuoteError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addQuoteError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addQuoteError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editQuoteSaga(action) {
  try {
    const res = yield call(editQuote, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editQuoteSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listQuoteAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editQuoteError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editQuoteError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editQuoteError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}


export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* QuoteSaga() {
  yield takeLatest(LIST_QUOTE, listQuoteSaga);
  yield takeEvery(ADD_QUOTE, addQuoteSaga);
  yield takeEvery(EDIT_QUOTE, editQuoteSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
