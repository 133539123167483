import React, { useEffect, useState, memo } from 'react';
import { Card, Table, Pagination } from 'antd';
import { ClientTableConfig } from './ClientTableConfig';
import { useSelector, useDispatch } from 'react-redux';
import reducer, { initialState } from './reducer';
import AddClientDrawer from 'components/AddClientDrawer';
import saga from './saga';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { deleteClient, listClient, toggleDeleteModal, toggleDrawer } from './action';
import _ from "lodash"
function Client() {
  useInjectReducer({ key: 'client', reducer });
  useInjectSaga({ key: 'client', saga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const clientState = useSelector((state) => state.client || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = ClientTableConfig(
      handleSearch,
      handleReset,
      onDelete,
      editClient,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listClient({ page: 1, limit: 10 }));
  }, []);

  const onDelete = (item) => {
    if (!_.isNil(item)) {
      selectedClient(item);
      dispatch(toggleDeleteModal());
      return;
    }
    dispatch(deleteClient(selectedClient.client_id));
  };

  const editClient = (client) => {
    setSelectedClient(client);
    dispatch(toggleDrawer());
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const resetPagination = () => setCurrentPage(1);

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    dispatch(listClient({ page, limit: pageSize }));
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  return (
    <Card
      title="Clients"
      extra={
        <AddClientDrawer
          initialState={initialState}
          selectedClient={selectedClient}
          resetPagination={resetPagination}
          editClient={editClient}
          roleAddResponsibility={_.includes(
            authState.userResponsibilities,
            'USER_CLIENT_ADD_BUTTON',
          )}
        />
      }
    >
      <Table
        columns={columns}
        loading={clientState.loading}
        bordered
        pagination={false}
        size={'small'}
        style={{ height: '70vh', overflow: 'scroll' }}
        dataSource={clientState.data}
      />
      <Pagination
        style={{ marginTop: '10px' }}
        total={clientState.totalCount}
        showTotal={(total) => `Total ${total} items`}
        defaultPageSize={10}
        current={currentPage}
        showLessItems={true}
        showSizeChanger={false}
        disabled={clientState.loading}
        onChange={onPageChange}
      />
    </Card>
  );
}

export default Client;
