import React, { useState, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { addPromotion, editPromotion } from 'pages/Promotion/action';
const { Option } = Select;

function AddPromotionDrawer({ initialState, selectedPromotion, editUserPromotion, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const promotionState = useSelector((state) => state.promotion || initialState);
  const [form] = Form.useForm();
  // const [defaultFileList, setDefaultFileList] = useState([]);
  // const [progress, setProgress] = useState(0);

  const togglePromotionDrawer = (open = false) => {
    form.resetFields();
    editUserPromotion(null);
  };
  if (!_.isNil(selectedPromotion)) {
    form.setFieldsValue({
      message: selectedPromotion.message,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }

  const handlePromotion = () => {
    form.validateFields().then((values) => {
      values.isactive = _.isNil(values.isactive) || !values.isactive ? false : true;

      if (selectedPromotion) {
        const { promotionid } = selectedPromotion;
        dispatch(editPromotion({ promotionid, ...values }));
      } else {
        dispatch(addPromotion(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => togglePromotionDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add Promotions
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedPromotion ? `Edit Promotion` : `Add Promotion`}
        width={800}
        destroyOnClose={true}
        onClose={() => togglePromotionDrawer()}
        visible={promotionState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => togglePromotionDrawer()}
              loading={promotionState.loading}
              disabled={promotionState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handlePromotion()}
              loading={false}
              disabled={promotionState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedPromotion ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical" initialValues={{ remember: false }}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="promotiontittle"
                label="Title"
                rules={[{ required: true, message: 'Please enter title' }]}
              >
                <Input placeholder="Please enter title" disabled={promotionState.loading} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="promotionoffer"
                label="Offer"
                rules={[{ required: true, message: 'Please enter offer' }]}
              >
                <Input placeholder="Please enter offer" disabled={promotionState.loading} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="promotionlink"
                label="Link"
                rules={[{ required: true, message: 'Please enter link' }]}
              >
                <Input placeholder="Please enter link" disabled={promotionState.loading} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="promotioncontent"
                label="Content"
                rules={[{ required: true, message: 'Please enter Content' }]}
              >
                <Input.TextArea
                  placeholder="Please enter Content"
                  rows={4}
                  allowClear={true}
                  disabled={promotionState.loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="isactive" label="Status">
                <Select placeholder="Status" defaultValue={true}>
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}

export default memo(AddPromotionDrawer);
