import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { listAttribute, addAttribute, editAttribute } from 'services/attribute';
import { ADD_ATTRIBUTE, EDIT_ATTRIBUTE, DELETE_ATTRIBUTE, LIST_ATTRIBUTE, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listAttribute as listAttributeAction, listAttributeSuccess, listAttributeError, addAttributeSuccess, addAttributeError, editAttributeSuccess, editAttributeError, toggleDrawer, toggleDeleteModal } from './action';

export function* listAttributeSaga(action) {
  try {
    const res = yield call(listAttribute, action.payload);
    const attributeData = JSON.parse(res.text);
    yield put(
      listAttributeSuccess({
        total: attributeData.attributes.total,
        attributes: attributeData.attributes.attributes.map((item) => ({
          ...item,
          key: item.attributeid,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listAttributeError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listAttributeError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addAttributeSaga(action) {
  try {
    const res = yield call(addAttribute, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addAttributeSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listAttributeAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addAttributeError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addAttributeError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addAttributeError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editAttributeSaga(action) {
  try {
    const res = yield call(editAttribute, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editAttributeSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listAttributeAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editAttributeError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editAttributeError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editAttributeError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

// export function* deleteAttributeSaga(action) {
//   try {
//     const res = yield call(deleteRole, action.payload);
//     const userData = JSON.parse(res.text);
//     yield put(toggleDeleteModal());
//     yield put(deleteRoleSuccess(userData));
//     yield call(listAttributeSaga);
//     message.success(userData.data.message);
//   } catch (err) {
//     if (_.isEqual(err.status, 401)) {
//       yield put(deleteRoleError());
//       yield put(authUserError({}));
//     }
//     if (_.isEqual(err.status, 422)) {
//       const serverError = JSON.parse(err.response.text);
//       message.error(serverError[0].message);
//       yield put(deleteRoleError());
//     }
//     if (_.isEqual(err.status, 500)) {
//       message.error('Something went wrong');
//     }
//   }
// }

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* roleSaga() {
  yield takeLatest(LIST_ATTRIBUTE, listAttributeSaga);
  yield takeEvery(ADD_ATTRIBUTE, addAttributeSaga);
  yield takeEvery(EDIT_ATTRIBUTE, editAttributeSaga);
  // yield takeEvery(DELETE_ATTRIBUTE, deleteAttributeSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
