
export const ADD_FAQ = 'ADD_FAQ';
export const ADD_FAQ_SUCCESS = 'ADD_FAQ_SUCCESS';
export const ADD_FAQ_ERROR = 'ADD_FAQ_ERROR';

export const EDIT_FAQ = 'EDIT_FAQ';
export const EDIT_FAQ_SUCCESS = 'EDIT_FAQ_SUCCESS';
export const EDIT_FAQ_ERROR = 'EDIT_FAQ_ERROR';

export const LIST_FAQ = 'LIST_FAQ';
export const LIST_FAQ_SUCCESS = 'LIST_FAQ_SUCCESS';
export const LIST_FAQ_ERROR = 'LIST_FAQ_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


