import React, { useEffect, useState } from 'react';
import { Card, Table, Pagination, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import _ from 'lodash';
import AddCouponDrawer from 'components/AddCouponDrawer/Index';
import categorySaga from 'pages/Category/saga';
import categoryReducer, { initialState as categoryInitialState } from 'pages/Category/reducer';
// import productSaga from 'pages/Product/saga';
// import productReducer, { initialState as productInitialState } from 'pages/Product/reducer';
import { CouponTableConfig } from './CouponTableConfig';
import { listDiscount, toggleDrawer, toggleDeleteModal } from './action';

function Coupon() {
  useInjectReducer({ key: 'discount', reducer });
  useInjectSaga({ key: 'discount', saga });
  useInjectReducer({ key: 'category', reducer: categoryReducer });
  useInjectSaga({ key: 'category', saga: categorySaga });
  // useInjectReducer({ key: 'product', reducer: productReducer });
  // useInjectSaga({ key: 'product', saga: productSaga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedDiscount, setselectedDiscount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const discountState = useSelector((state) => state.discount || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = CouponTableConfig(
      handleSearch,
      handleReset,
      editUserDiscount,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listDiscount({ page: 1, limit: 10 }));
  }, []);

  const resetPagination = () => setCurrentPage(1);

  const editUserDiscount = (discount) => {
    setselectedDiscount(discount);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    let filter = { page, limit: pageSize };
    setCurrentPage(page);
    if ((searchedColumn, searchText)) {
      filter = { ...filter, [searchedColumn]: searchText };
    }
    dispatch(listDiscount(filter));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys.length <= 0) {
      message.error('Please select to search');
      return;
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    dispatch(listDiscount({ page: 1, limit: 10, [dataIndex]: selectedKeys[0] }));
    resetPagination();
  };
  // const handleDeleteModalToggle = () => dispatch(toggleDeleteModal());

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(null);
    setSearchedColumn(null);
    dispatch(listDiscount({ page: 1, limit: 10 }));
    resetPagination();
  };
  return (
    <>
      <Card
        title="Manage Coupons"
        extra={
          <AddCouponDrawer
            initialState={initialState}
            categoryInitialState={categoryInitialState}
            // productInitialState={productInitialState}
            selectedDiscount={selectedDiscount}
            resetPagination={resetPagination}
            editUserDiscount={editUserDiscount}
            roleAddResponsibility={_.includes(
              authState.userResponsibilities,
              'PRODUCT_COUPON_ADD_BUTTON',
            )}
          />
        }
      >
        <Table
          columns={columns}
          loading={discountState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={discountState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={discountState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={discountState.loading}
          onChange={onPageChange}
        />
      </Card>
      {/* <ConfirmModal
        title={'Delete Portfolio Confirmation'}
        text={'Are you sure you want to delete this portfolio ?'}
        toggle={discountState.deleteModal}
        loading={discountState.loading}
        handleDeleteModalToggle={handleDeleteModalToggle}
        onDelete={onDelete}
      /> */}
    </>
  );
}

export default Coupon;
