import * as Constant from './constant';

export const initialState = { totalCount: 0, data: [], activeallClients: [], clientUser: [], loading: false, error: false, drawer: false, deleteModal: false };

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_CLIENT:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
      };
    case Constant.LIST_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.clients,
        totalCount: action.payload.total,
        error: false,
      };
    case Constant.LIST_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };
    case Constant.ADD_CLIENT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ADD_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.ACTIVE_ALL_CLIENT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ACTIVE_ALL_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        activeallClients: action.payload,
        error: false,
      };
    case Constant.ACTIVE_ALL_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        activeallClients: [],
        error: true,
      };
    case Constant.ADD_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.EDIT_CLIENT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.EDIT_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.DELETE_CLIENT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.DELETE_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.TOGGLE_DRAWER:
      return {
        ...state,
        drawer: !state.drawer,
      };
    case Constant.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !state.deleteModal,
      };
    case Constant.CLEAR_CLIENT_USER_LIST:
      return {
        ...state,
        clientUser: [],
      };
    case Constant.LIST_CLIENT_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.LIST_CLIENT_USER_SUCCESS:
      return {
        ...state,
        clientUser: action.payload,
        loading: false,
        error: false,
      };
    case Constant.LIST_CLIENT_USER_ERROR:
      return {
        ...state,
        clientUser: action.payload,
        loading: false,
        error: true,
      };
    case Constant.ADD_CLIENT_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ADD_CLIENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.ADD_CLIENT_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.EDIT_CLIENT_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.EDIT_CLIENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.EDIT_CLIENT_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.DELETE_CLIENT_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.DELETE_CLIENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.DELETE_CLIENT_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default clientReducer;
