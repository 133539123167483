import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Unauthorized from "components/Unauthorized";
import PrivateRoute from "pages/PrivateRoute";
import AppLogin from "pages/AppLogin";
import Dashboard from "pages/Dashboard";
import Users from "pages/Users";
import Role from "pages/Role";
import Responsibilities from "pages/Responsibilities";
import UpdateOrder from "pages/UpdateOrder";
import CancelOrder from "pages/Orders/CancelOrder";
import Orders from 'pages/Orders';
import Client from "pages/Client";
import Invoice from "pages/Invoices";
import InvoiceSetting from "pages/InvoiceSettting/Index";
import Product from "pages/Product";
import ProductConfigDetails from "pages/ProductConfig";
import Category from "pages/Category";
import Attribute from "pages/Attribute";
import Addon from "pages/Addons";
import Coupon from "pages/Coupons";
import Portfolio from "pages/Portfolios";
import SatisfactionRate from "pages/SatisfactionRate";
import Testimonials from "pages/Testimonials";
import ManageQoutes from "pages/ManageQoutes";
import LinodeServerDetails from "pages/PurchasedProducts/LinodeServer";
import UserReport from "pages/Reports/UserReports";
import IncomeReport from "pages/Reports/IncomeReport";
import SubscriptionReports from "pages/Reports/Subscription";
import SharedDetails from "pages/PurchasedProducts/SharedProduct";
import DedicatedDetails from "pages/PurchasedProducts/DedicatedProduct";
import NotificationReport from "pages/Reports/Notification";
import FAQ from "pages/Faq";
import Promotions from "pages/Promotion";
import Howto from "pages/Howto";
import AddDNS from "pages/Addons/AddDNS";
import AddSMTP from "pages/Addons/AddSMTP";
import AddEmail from "pages/Addons/AddEmail";
import LogReports from "pages/Reports/LogsReport";
import EventReports from "pages/Reports/Event";
import Contact from "pages/Contact";
import WelcomeMessage from "pages/Message";
import OneClick from "pages/OneClick";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact={true}>
            <AppLogin />
          </Route>

          <Main>
            <Route
              component={({ match }) => (
                <div>
                  <PrivateRoute path="/dashboard" component={Dashboard} tab={"APP_DASHBOARD"} />
                  <PrivateRoute path="/users" component={Users} tab={"USER_OPEN_MANAGE_SUBTAB"} />
                  <PrivateRoute path="/role" component={Role} tab={"ACL_ROLE_MANAGE_SUBTAB"} />
                  <PrivateRoute path="/responsibilities" component={Responsibilities} tab={"ACL_RESPONSIBILITIES_MANAGE_SUBTAB"} />
                  <PrivateRoute path="/clients" component={Client} tab={'USER_CLIENT_MANAGE_SUBTAB'} />
                  <PrivateRoute path="/invoices" component={Invoice} tab={'USER_INVOICE_MANAGE_SUBTAB'} />
                  <PrivateRoute path="/invoicesettings" component={InvoiceSetting} tab={'SETTING_INVOICE_SUBTAB'} />
                  <PrivateRoute path="/updateorders" component={UpdateOrder} tab={'UPDATE_ORDER_SUBTAB'} />
                  <PrivateRoute path="/cancelorders" component={CancelOrder} tab={'CANCEL_ORDER_SUBTAB'} />
                  <PrivateRoute path="/products" component={Product} tab={'PRODUCT_PROD_MANAGE_SUBTAB'} />
                  <PrivateRoute path="/productconfig" component={ProductConfigDetails} tab={'PRODUCT_CONFIG_SUBTAB'} />
                  <PrivateRoute path="/categories" component={Category} tab={'PRODUCT_CATEGORY_MANAGE_SUBTAB'} />
                  <PrivateRoute path="/attributes" component={Attribute} tab={'PRODUCT_ATTRIBUTE_MANAGE_SUBTAB'} />
                  <PrivateRoute path="/clients" component={Client} tab={'USER_CLIENT_MANAGE_SUBTAB'} />
                  <PrivateRoute path="/addons" component={Addon} tab={'PRODUCT_ADDON_MANAGE_SUBTAB'} />
                  <PrivateRoute path="/coupons" component={Coupon} tab={'PRODUCT_COUPON_MANAGE_SUBTAB'} />
                  <PrivateRoute path="/portfolio" component={Portfolio} tab={'PRODUCT_PORTFOLIO_MANAGE_SUBTAB'} />
                  <PrivateRoute path="/satisfactionrates" component={SatisfactionRate} tab={'MANAGE_SATISFACTION_RATE_SUBTAB'} />
                  <PrivateRoute path="/testimonials" component={Testimonials} tab={'MANANGE_TESTIMONIAL_SUBTAB'} />
                  <PrivateRoute path="/manageqoutes" component={ManageQoutes} tab={'MANANGE_QUOTES_SUBTAB'} />
                  <PrivateRoute path="/serverdetails" component={LinodeServerDetails} tab={'SERVER_DETAILS_SUBTAB'} />
                  <PrivateRoute path="/userreports" component={UserReport} tab={'USER_REPORT_SUBTAB'} />
                  <PrivateRoute path="/incomereports" component={IncomeReport} tab={'INCOME_REPORT_SUBTAB'} />
                  <PrivateRoute path="/subscriptionreports" component={SubscriptionReports} tab={'SUBSCRIPTION_REPORT_SUBTAB'} />
                  <PrivateRoute path="/shareddetial" component={SharedDetails} tab={'SHARED_DETAILS_SUBTAB'} />
                  <PrivateRoute path="/dedicateddetial" component={DedicatedDetails} tab={'DEDICATED_PRODUCT_SUBTAB'} />
                  <PrivateRoute path="/notificationlog" component={NotificationReport} tab={'NOTIFICATION_SUBTAB'} />
                  <PrivateRoute path="/faq" component={FAQ} tab={'FAQ_SUBTAB'} />
                  <PrivateRoute path="/promotions" component={Promotions} tab={'PROMOTION_SUBTAB'} />
                  <PrivateRoute path="/howto" component={Howto} tab={'HOWTO_SUBTAB'} />
                  <PrivateRoute path="/adddns" component={AddDNS} tab={'ADD_DNS_SUBTAB'} />
                  <PrivateRoute path="/addsmtp" component={AddSMTP} tab={'ADD_SMTP_SUBTAB'} />
                  <PrivateRoute path="/addemail" component={AddEmail} tab={'ADD_EMAIL_SUBTAB'} />
                  <PrivateRoute path="/logreports" component={LogReports} tab={'LOG_REPORT_SUBTAB'} />
                  <PrivateRoute path="/eventslog" component={EventReports} tab={'EVENT_LOG_SUBTAB'} />
                  <PrivateRoute path="/contact" component={Contact} tab={'CONTACT_SUBTAB'} />
                  <PrivateRoute path="/message" component={WelcomeMessage} tab={'MESSAGE_SUBTAB'} />
                  <PrivateRoute path="/oneclick" component={OneClick} tab={'ONECLICK_SUBTAB'} />
                  <PrivateRoute path="/orders" component={Orders} tab={'USER_ORDER_MANAGE_SUBTAB'} />
                  <PrivateRoute path="/unauthorized" component={Unauthorized} tab={null} />
                </div>
              )}
            />
          </Main>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
