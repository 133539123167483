import { Input, Button, Space } from "antd";
import { SearchOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import _ from "lodash";

export const RoleTableConfig = (handleSearch, handleReset, onDelete, editRole, responsibilities) => {
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    };
  };
  return [
    // {
    //   title: 'ID',
    //   dataIndex: 'roleid',
    //   key: 'roleid',
    //   width: '2%',
    // },
    {
      title: "Name",
      dataIndex: "rolename",
      key: "rolename",
      width: "3%",
      // align: "center",
      ...getColumnSearchProps("rolename"),
    },
    {
      title: "Description",
      dataIndex: "roledescription",
      key: "roledescription",
      width: "10%",
      // align: "center",
      ...getColumnSearchProps("roledescription"),
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: '10%',
    //   ...getColumnSearchProps('status'),
    //   render: (status) => <Tag color="green">{status}</Tag>,
    // },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      // align: "center",
      width: "2%",
      render: (item) => {
        return (
          <div>
            <Space size="middle">
              {/* <EyeOutlined style={{ color: '#0D8616', cursor: 'pointer', fontSize: '20px' }} /> */}
              {_.includes(responsibilities, "ROLE_EDIT_BUTTON") ? (
                <EditOutlined
                  style={{
                    color: "#0D4B86",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                  onClick={() => editRole(item)}
                />
              ) : (
                ""
              )}
              {_.includes(responsibilities, "ROLE_DELETE_BUTTON") ? (
                <DeleteOutlined
                  style={{
                    color: "#F52A0E",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                  onClick={() => onDelete(item)}
                />
              ) : (
                ""
              )}
            </Space>
          </div>
        );
      },
    },
  ];
};
