import React, { memo } from "react";
import { Input, Form, Button, Drawer, Col, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { addRole, editRole } from "pages/Role/action";
import _ from "lodash";

function AddRoleDrawer({ initialState, selectedRole, editUserRole, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role || initialState);
  const [form] = Form.useForm();

  if (!_.isNil(selectedRole)) {
    form.setFieldsValue({
      rolename: selectedRole.rolename,
      roledescription: selectedRole.roledescription,
    });
  }

  const toggleRoleDrawer = (open = false) => {
    form.resetFields();
    editUserRole(null);
  };

  const handleUserRole = () => {
    form.validateFields().then((values) => {
      if (selectedRole) {
        const { roleid } = selectedRole;
        dispatch(editRole({ roleid, ...values }));
      } else {
        dispatch(addRole(values));
      }
    });
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => toggleRoleDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add Role
        </Button>
      ) : (
        ""
      )}
      <Drawer
        title={selectedRole ? `Edit Role` : `Add Role`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleRoleDrawer()}
        visible={roleState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => toggleRoleDrawer()}
              loading={roleState.loading}
              disabled={roleState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleUserRole()}
              loading={false}
              disabled={roleState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedRole ? "Edit" : "Save"}
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical" initialValues={{ remember: false }}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="rolename"
                label="Role Name"
                rules={[{ required: true, message: "Please enter role name" }]}
              >
                <Input
                  placeholder="Please enter role name"
                  disabled={roleState.loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="roledescription"
                label="Role Description"
                rules={[
                  { required: true, message: "Please enter role description" },
                ]}
              >
                <Input
                  placeholder="Please enter role description"
                  disabled={roleState.loading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}

export default memo(AddRoleDrawer);
