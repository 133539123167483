import { Table, Input, Button, Space, Typography, Tag } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const OrderAddonCartTableConfig = (removeAddonFromCart) => {
  return [
    {
      title: 'Addon Name',
      dataIndex: 'addonname',
      key: 'addonname',
      width: '10%',
    },
    {
      title: 'Attribute Name',
      dataIndex: 'attributename',
      key: 'attributename',
      width: '10%',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '10%',
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '2%',
      render: (item) => {
        return (
          <div>
            <Space size="middle">
              <DeleteOutlined
                style={{ color: '#0D4B86', cursor: 'pointer', fontSize: '20px' }}
                onClick={() => removeAddonFromCart(item)}
              />
            </Space>
          </div>
        );
      },
    },
  ];
};
