import React, { useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Input, Form, Button, Drawer, Col, Row, Select, message } from "antd";
import { addUser, editUser } from "pages/Users/action";
// import generator from "generate-password";

function AddUserDrawer({ initialState, selectedUser, roles, editAdminUser, userAddResponsibility }) {
  const [form] = Form.useForm();
  const userState = useSelector((state) => state.user || initialState);
  const dispatch = useDispatch();
  if (!_.isNil(selectedUser)) {
    form.setFieldsValue({
      first_name: selectedUser.first_name,
      last_name: selectedUser.last_name,
      email: selectedUser.email,
      user_role_id: selectedUser.user_role_id,
    });
  }
  const toggleUserDrawer = (open = false) => {
    form.resetFields();
    editAdminUser(null);
  };
  const { Option } = Select;

  const handleUser = () => {
    form.validateFields().then((values) => {
      const { password, confirm_password } = values;
      const status = values.status || true;
      values.status = status;
      if (!_.isEqual(password, confirm_password)) {
        message.error("Your password and confirm password does not match");
        return;
      } else {
        delete values.confirm_password;
      }
      if (selectedUser) {
        delete values.email;
        const userData = _.omitBy(values, _.isNil);
        dispatch(
          editUser({ ...userData, user_id: selectedUser.admin_user_id })
        );
      } else {
        dispatch(addUser(values));
        form.resetFields();
      }
    });
  };

  const [password, setPassword] = useState("");
  // const [length, setLength] = useState(12);
  // const [isLowerCase, setIsLowerCase] = useState(true);
  // const [isUpperCase, setIsUpperCase] = useState(false);
  // const [isNumbers, setIsNumbers] = useState(false);
  // const [isSymbols, setIsSymbols] = useState(false);

  // const generatePassword = () => {
  //   const pwd = generator.generate({
  //     length: length,
  //     lowercase: isLowerCase,
  //     uppercase: isUpperCase,
  //     numbers: isNumbers,
  //     symbols: isSymbols,
  //   });
  //   setPassword(pwd);
  // };
  return (
    <div>
      {userAddResponsibility ? (
        <Button
          onClick={() => toggleUserDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add User
        </Button>
      ) : (
        ""
      )}
      <Drawer
        title={selectedUser ? "Edit User" : "Add User"}
        width={800}
        onClose={() => toggleUserDrawer()}
        visible={userState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => toggleUserDrawer()}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleUser()}
              loading={userState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedUser ? "Edit" : "Save"}
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical" initialValues={{ remember: true }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[{ required: true, message: "Please enter first name" }]}
              >
                <Input placeholder="Please enter first name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                  { required: true, message: "Please enter your Last Name" },
                ]}
              >
                <Input placeholder="Please enter your last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row >
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please enter your email" }]}
              >
                <Input placeholder="Please enter your email" />
              </Form.Item>
            </Col>

          </Row>
          {/* <Row>
            <Col span={6}>
              <Form.Item rules={[{ required: false }]}>
                <Checkbox
                  checked={isLowerCase}
                  onChange={() => setIsLowerCase((val) => !val)}
                >
                  Lower Case
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item rules={[{ required: false }]}>
                <Checkbox
                  checked={isUpperCase}
                  onChange={() => setIsUpperCase((val) => !val)}
                >
                  Upper Case
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item rules={[{ required: false }]}>
                <Checkbox
                  checked={isNumbers}
                  onChange={() => setIsNumbers((val) => !val)}
                >
                  Numbers
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item rules={[{ required: false }]}>
                <Checkbox
                  checked={isSymbols}
                  onChange={() => setIsSymbols((val) => !val)}
                >
                  Symbols
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                rules={[
                  { required: false, message: "Please enter discount code" },
                ]}
              >
                <Input value={password} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Button
                value="Generate Password"
                onClick={generatePassword}
                style={{
                  backgroundColor: "#0069d9",
                  borderColor: "#0069d9",
                  color: "white",
                }}
              >
                Generate Password
              </Button>
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: selectedUser ? false : true,
                    message: "Please enter your password",
                  },
                ]}
              >
                <Input.Password
                  style={{ width: "100%" }}
                  value={password}
                  placeholder="Please enter your password"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirm_password"
                label="Confirm Password"
                rules={[
                  {
                    required: selectedUser ? false : true,
                    message: "Please re-enter your passsword",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Please enter your address"
                  value={password}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="status" label="Status">
                <Select placeholder="Status" defaultValue={true}>
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="user_role_id"
                label="Select Role"
                initialValue={selectedUser?.user_role_id}
                rules={[{ required: true, message: "Please Select User Role" }]}
              >
                <Select placeholder="Select Role">
                  {roles.map((rol) => (
                    <Option key={rol.roleid} value={rol.roleid}>
                      {rol.rolename}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}

export default AddUserDrawer;
