export const ADD_INVOICE = 'ADD_INVOICE';
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS';
export const ADD_INVOICE_ERROR = 'ADD_INVOICE_ERROR';

export const INVOICE_DETAIL = 'INVOICE_DETAIL';
export const INVOICE_DETAIL_SUCCESS = 'INVOICE_DETAIL_SUCCESS';
export const INVOICE_DETAIL_ERROR = 'INVOICE_DETAIL_ERROR';

export const EDIT_INVOICE = 'EDIT_INVOICE';
export const EDIT_INVOICE_SUCCESS = 'EDIT_INVOICE_SUCCESS';
export const EDIT_INVOICE_ERROR = 'EDIT_INVOICE_ERROR';

export const LIST_INVOICE = 'LIST_INVOICE';
export const LIST_INVOICE_SUCCESS = 'LIST_INVOICE_SUCCESS';
export const LIST_INVOICE_ERROR = 'LIST_INVOICE_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';
