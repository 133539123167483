import React, { useEffect, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { addQuote, editQuote } from 'pages/ManageQoutes/action';
import { activeAllClient } from 'pages/Client/action';
const { Option } = Select;

function AddManageQuoteDrawer({ initialState, clientInitialState, selectedQuote, editUserQuote, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const clientState = useSelector((state) => state.client || clientInitialState);
  const quopteState = useSelector((state) => state.quote || initialState);
  const [form] = Form.useForm();
  // const [defaultFileList, setDefaultFileList] = useState([]);
  // const [progress, setProgress] = useState(0);
  // const uploadImage = async (options) => {
  //   const { onSuccess, onError, file, onProgress } = options;
  //   const fmData = new FormData();
  //   const config = {
  //     headers: { 'content-type': 'multipart/form-data' },
  //     onUploadProgress: (event) => {
  //       const percent = Math.floor((event.loaded / event.total) * 100);
  //       setProgress(percent);
  //       if (percent === 100) {
  //         setTimeout(() => setProgress(0), 1000);
  //       }
  //       onProgress({ percent: (event.loaded / event.total) * 100 });
  //     },
  //   };
  //   fmData.append('image', file);
  //   try {
  //     const res = await axios.post('https://jsonplaceholder.typicode.com/posts', fmData, config);
  //     onSuccess('Ok');
  //     console.log('server res: ', res);
  //   } catch (err) {
  //     console.log('Eroor: ', err);
  //     const error = new Error('Some error');
  //     onError({ err });
  //   }
  // };
  // const handleOnChange = ({ file, fileList, event }) => {
  //   console.log(file, fileList, event);
  //   //Using Hooks to update the state to the current filelist
  //   setDefaultFileList(fileList);
  //   //filelist - [{uid: "-1",url:'Some url to image'}]
  // };

  const toggleQuoteDrawer = (open = false) => {
    form.resetFields();
    editUserQuote(null);
  };
  if (!_.isNil(selectedQuote)) {
    form.setFieldsValue({
      client_id: selectedQuote.client_id,
      quote_request: selectedQuote.quote_request,
      quote_response: selectedQuote.quote_response,
      // created_at: selectedQuote.created_at,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }
  useEffect(() => {
    if (quopteState.drawer) {
      dispatch(activeAllClient());
    }
  }, [clientState.drawer]);
  const habdleQuote = () => {
    form.validateFields().then((values) => {
      if (selectedQuote) {
        const { quote_id } = selectedQuote;
        dispatch(editQuote({ quote_id, ...values }));
      } else {
        dispatch(addQuote(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => toggleQuoteDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add Quote
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedQuote ? `Edit Quote` : `Add Quote`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleQuoteDrawer()}
        visible={quopteState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggleQuoteDrawer()}
              loading={quopteState.loading}
              disabled={quopteState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => habdleQuote()}
              loading={false}
              disabled={quopteState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedQuote ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={clientState.loading && quopteState.loading}
          active={clientState.loading && quopteState.loading}
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="client_id"
                  label="Select Client"
                  initialValue={selectedQuote?.client_id}
                  rules={[
                    {
                      required: false,
                      message: 'Please select client',
                    },
                  ]}
                >
                  <Select placeholder="Select client" loading={clientState.loading}>
                    {clientState.activeallClients.map((cat) => (
                      <Option value={cat.client_id} key={cat.client_id}>
                        {cat.client_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="quote_request"
                  label="Quote Request"
                  rules={[{ required: true, message: 'Please enter request' }]}
                >
                  <Input.TextArea
                    placeholder="Please enter request"
                    rows={4}
                    allowClear={true}
                    disabled={quopteState.loading}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="quote_response"
                  label="Quote Response"
                  rules={[{ required: true, message: 'Please enter response' }]}
                >
                  <Input.TextArea
                    placeholder="Please enter response"
                    rows={4}
                    allowClear={true}
                    disabled={quopteState.loading}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
            <Col span={12}>
              <Form.Item name="created_at" label="Created at">
                <DatePicker style={{ width: '100%' }}  disabled={quopteState.loading} />
              </Form.Item>
            </Col>
            </Row> */}
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddManageQuoteDrawer);
