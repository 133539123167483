import React, { useState, useEffect, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { activeCategory } from 'pages/Category/action';
import { addSatisfaction, editSatisfaction } from 'pages/SatisfactionRate/action';
const { Option } = Select;

function AddSatisfactionDrawer({ initialState, categoryInitialState, selectedSatisfaction, editUserSatisfaction, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.category || categoryInitialState);
  const satisfactionState = useSelector((state) => state.satisfaction || initialState);
  const [form] = Form.useForm();

  const toggleSatisfactionDrawer = (open = false) => {
    form.resetFields();
    editUserSatisfaction(null);
  };
  if (!_.isNil(selectedSatisfaction)) {
    form.setFieldsValue({
      ratevalue: selectedSatisfaction.ratevalue,
      productcategoryid: selectedSatisfaction.productcategoryid,
      isenabled: _.isEqual(selectedSatisfaction.isenabled, 1) ? true : false,
      isactive: selectedSatisfaction.isactive,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }
  useEffect(() => {
    if (satisfactionState.drawer) {
      dispatch(activeCategory());
    }
  }, [categoryState.drawer]);
  const handleSatisfaction = () => {
    form.validateFields().then((values) => {
      values.isactive = _.isNil(values.isactive) || !values.isactive ? false : true;

      if (selectedSatisfaction) {
        const { rate_id } = selectedSatisfaction;
        dispatch(editSatisfaction({ rate_id, ...values }));
      } else {
        dispatch(addSatisfaction(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => toggleSatisfactionDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add Satisfaction
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedSatisfaction ? `Edit Satisfaction Rate` : `Add Satisfaction Rate`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleSatisfactionDrawer()}
        visible={satisfactionState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggleSatisfactionDrawer()}
              loading={satisfactionState.loading}
              disabled={satisfactionState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSatisfaction()}
              loading={false}
              disabled={satisfactionState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedSatisfaction ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={categoryState.loading && satisfactionState.loading}
          active={categoryState.loading && satisfactionState.loading}
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="productcategoryid"
                  label="Select Category"
                  initialValue={selectedSatisfaction?.productcategoryid}
                  rules={[
                    {
                      required: false,
                      message: 'Please select category',
                    },
                  ]}
                >
                  <Select placeholder="Select Category" loading={categoryState.loading}>
                    {categoryState.activeCategories.map((cat) => (
                      <Option value={cat.productcatid} key={cat.productcatid}>
                        {cat.productcatname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="ratevalue"
                  label="Value"
                  rules={[{ required: false, message: 'Please enter value' }]}
                >
                  <Input placeholder="Please enter value" disabled={satisfactionState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="isactive" label="Status">
                  <Select placeholder="Status" defaultValue={true}>
                    <Option value={true}>Active</Option>
                    <Option value={false}>Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddSatisfactionDrawer);
