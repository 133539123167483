import React, { useEffect, memo } from 'react';
import { Form, Button, Drawer, Col, Row, Select, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { activeAllProducts } from 'pages/Product/action';
import { activeAllClient } from 'pages/Client/action';
import { addProductconf, editProductconf } from 'pages/ProductConfig/action';
const { Option } = Select;

function AddProductConfigDrawer({ initialState, productInitialState, selectedProductconf, clientInitialState, editUserProductconf, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const productconfState = useSelector((state) => state.productconf || initialState);
  const clientState = useSelector((state) => state.client || clientInitialState);
  const productState = useSelector((state) => state.product || productInitialState);
  const [form] = Form.useForm();

  const toggleProductconfDrawer = (open = false) => {
    form.resetFields();
    editUserProductconf(null);
  };
  if (!_.isNil(selectedProductconf)) {
    form.setFieldsValue({
      productid: selectedProductconf.productid,
      clientid: selectedProductconf.clientid,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }
  useEffect(() => {
    if (productconfState.drawer) {
      dispatch(activeAllProducts());
    }
  }, [productState.drawer]);
  useEffect(() => {
    if (productconfState.drawer) {
      dispatch(activeAllClient());
    }
  }, [clientState.drawer]);
  const handleProductConfig = () => {
    form.validateFields().then((values) => {
      if (selectedProductconf) {
        const { prodconfig_id } = selectedProductconf;
        dispatch(editProductconf({ prodconfig_id, ...values }));
      } else {
        dispatch(addProductconf(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => toggleProductconfDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Configure Product
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedProductconf ? `Edit` : `Configure`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleProductconfDrawer()}
        visible={productconfState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggleProductconfDrawer()}
              loading={productconfState.loading}
              disabled={productconfState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleProductConfig()}
              loading={false}
              disabled={productconfState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedProductconf ? 'Edit' : 'Configure'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={productState.loading && productconfState.loading && clientState.loadin}
          active={productState.loading && productconfState.loading && clientState.loadin}
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="productid"
                  label="Select Product"
                  initialValue={selectedProductconf?.productid}
                  rules={[
                    {
                      required: false,
                      message: 'Please select Product',
                    },
                  ]}
                >
                  <Select placeholder="Select Product" loading={productState.loading}>
                    {productState.activeallProducts.map((cat) => (
                      <Option value={cat.productid} key={cat.productid}>
                        {cat.productname} ({cat.productid})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="clientid"
                  label="Select Client"
                  initialValue={selectedProductconf?.client_id}
                  rules={[
                    {
                      required: false,
                      message: 'Please select client',
                    },
                  ]}
                >
                  <Select placeholder="Select client" loading={clientState.loading}>
                    {clientState.activeallClients.map((cat) => (
                      <Option value={cat.client_id} key={cat.client_id}>
                        {cat.client_name} {cat.last_name} ({cat.client_id})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddProductConfigDrawer);
