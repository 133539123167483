import * as Constant from "./constant";

export const initialState = { clientStats: [], totalCount: 0 };

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.CLIENT_STATS:
      return {
        ...state,
        loading: true,
        clientStats: [],
        error: false,
      };
    case Constant.CLIENT_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientStats: action.payload,
        totalCount: action.payload,
        error: false,
      };
    case Constant.CLIENT_STATS_ERROR:
      return {
        ...state,
        loading: false,
        clientStats: [],
        data: [],
        error: true,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
