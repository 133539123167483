import { call, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { listAddon, addAddon, listAddonCategory, editAddon, getAddonDrawerData } from 'services/addon';
import { ADD_ADDON, EDIT_ADDON, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL, LIST_ADDON, LIST_ADDON_CATEGORY, DRAWER_DATA, ADD_ADDON_ATTRIBUTE, REMOVE_ADDON_ATTRIBUTE, CLEAR_ADDON_ATTRIBUTE } from './constant';
import { addAddonSuccess, addAddonError, toggleDrawer, toggleDeleteModal, listAddon as listAddonAction, listAddonSuccess, listAddonError, listAddonCategorySuccess, listAddonCategoryError, editAddonSuccess, editAddonError, drawerDataSuccess, drawerDataError, addAddonAttribute, removeAddonAttribute, clearAddonAttribute } from './action';

export function* addAddonSaga(action) {
  try {
    const res = yield call(addAddon, action.payload);
    const userData = JSON.parse(res.text);
    message.success(userData.data.message);
    yield put(addAddonSuccess());
    yield put(toggleDrawer());
    yield put(listAddonAction({ page: 1, limit: 10 }));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addAddonError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addAddonError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addAddonError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editAddonSaga(action) {
  try {
    const res = yield call(editAddon, action.payload);
    const userData = JSON.parse(res.text);
    message.success(userData.data.message);
    yield put(editAddonSuccess());
    yield put(toggleDrawer());
    yield put(listAddonAction({ page: 1, limit: 10 }));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editAddonError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editAddonError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editAddonError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* listAddonSaga(action) {
  try {
    const res = yield call(listAddon, action.payload);
    const addonData = JSON.parse(res.text);
    const { addons, total } = addonData.data;
    yield put(
      listAddonSuccess({
        total,
        addons: addons.map((item) => ({
          ...item,
          key: item.addonid,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listAddonError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listAddonError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* listAddonCategorySaga(action) {
  try {
    const res = yield call(listAddonCategory);
    const categoryData = JSON.parse(res.text);
    const data = categoryData.data;
    yield put(listAddonCategorySuccess(data));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listAddonCategoryError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listAddonCategoryError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* drawerDataSaga(action) {
  try {
    const res = yield call(getAddonDrawerData);
    const drawerData = JSON.parse(res.text);
    const attributes = drawerData.data.attributes.map((attr) => ({
      ...attr,
      key: attr.attributeid,
    }));
    const categories = drawerData.data.categories.map((cat) => ({
      ...cat,
      key: cat.productcatid,
    }));
    yield put(drawerDataSuccess({ categories, attributes }));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(drawerDataError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export function* addAddonAttributeSaga(action) {
  put(addAddonAttribute(action.payload));
}

export function* removeAddonAttributeSaga(action) {
  put(removeAddonAttribute(action.payload));
}

export function* clearAddonAttributeSaga(action) {
  put(clearAddonAttribute());
}

export default function* roleSaga() {
  yield takeEvery(ADD_ADDON, addAddonSaga);
  yield takeEvery(EDIT_ADDON, editAddonSaga);
  yield takeEvery(LIST_ADDON, listAddonSaga);
  yield takeEvery(LIST_ADDON_CATEGORY, listAddonCategorySaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
  yield takeEvery(DRAWER_DATA, drawerDataSaga);
  yield takeEvery(ADD_ADDON_ATTRIBUTE, addAddonAttributeSaga);
  yield takeEvery(REMOVE_ADDON_ATTRIBUTE, removeAddonAttributeSaga);
  yield takeEvery(CLEAR_ADDON_ATTRIBUTE, clearAddonAttributeSaga);
}