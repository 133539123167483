import * as Constant from './constant';

export const listRoles = () => {
  return {
    type: Constant.LIST_ROLES,
  };
};

export const listRolesSuccess = (payload) => {
  return {
    type: Constant.LIST_ROLES_SUCCESS,
    payload,
  };
};

export const listRolesError = () => {
  return {
    type: Constant.LIST_ROLES_ERROR,
  };
};

export const addRole = (payload) => {
  return {
    type: Constant.ADD_ROLE,
    payload,
  };
};

export const addRoleSuccess = (payload) => {
  return {
    type: Constant.ADD_ROLE_SUCCESS,
    payload,
  };
};

export const addRoleError = () => {
  return {
    type: Constant.ADD_ROLE_ERROR,
  };
};

export const editRole = (payload) => {
  return {
    type: Constant.EDIT_ROLE,
    payload,
  };
};

export const editRoleSuccess = (payload) => {
  return {
    type: Constant.EDIT_ROLE_SUCCESS,
    payload,
  };
};

export const editRoleError = () => {
  return {
    type: Constant.EDIT_ROLE_ERROR,
  };
};

export const deleteRole = (payload) => {
  return {
    type: Constant.DELETE_ROLE,
    payload,
  };
};

export const deleteRoleSuccess = (payload) => {
  return {
    type: Constant.DELETE_ROLE_SUCCESS,
    payload,
  };
};

export const deleteRoleError = () => {
  return {
    type: Constant.DELETE_ROLE_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
