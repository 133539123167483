
import * as Constant from './constant';

export const addPortfolio = (payload) => {
  return {
    type: Constant.ADD_PORTFOLIO,
    payload,
  };
};

export const addPortfolioSuccess = () => {
  return {
    type: Constant.ADD_PORTFOLIO_SUCCESS,
  };
};

export const addPortfolioError = () => {
  return {
    type: Constant.ADD_PORTFOLIO_ERROR,
  };
};

export const editPortfolio = (payload) => {
  return {
    type: Constant.EDIT_PORTFOLIO,
    payload,
  };
};

export const editPortfolioSuccess = () => {
  return {
    type: Constant.EDIT_PORTFOLIO_SUCCESS,
  };
};

export const editPortfolioError = () => {
  return {
    type: Constant.EDIT_PORTFOLIO_ERROR,
  };
};

export const listPortfolio = (payload) => {
  return {
    type: Constant.LIST_PORTFOLIO,
    payload,
  };
};

export const listPortfolioSuccess = (payload) => {
  return {
    type: Constant.LIST_PORTFOLIO_SUCCESS,
    payload,
  };
};

export const listPortfolioError = () => {
  return {
    type: Constant.LIST_PORTFOLIO_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
