
export const ADD_SHARED = 'ADD_SHARED';
export const ADD_SHARED_SUCCESS = 'ADD_SHARED_SUCCESS';
export const ADD_SHARED_ERROR = 'ADD_SHARED_ERROR';

export const EDIT_SHARED = 'EDIT_SHARED';
export const EDIT_SHARED_SUCCESS = 'EDIT_SHARED_SUCCESS';
export const EDIT_SHARED_ERROR = 'EDIT_SHARED_ERROR';

export const LIST_SHARED = 'LIST_SHARED';
export const LIST_SHARED_SUCCESS = 'LIST_SHARED_SUCCESS';
export const LIST_SHARED_ERROR = 'LIST_SHARED_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


