
export const ADD_ORDER_UPDATE = 'ADD_ORDER_UPDATE';
export const ADD_ORDER_UPDATE_SUCCESS = 'ADD_ORDER_UPDATE_SUCCESS';
export const ADD_ORDER_UPDATE_ERROR = 'ADD_ORDER_UPDATE_ERROR';

export const EDIT_ORDER_UPDATE = 'EDIT_ORDER_UPDATE';
export const EDIT_ORDER_UPDATE_SUCCESS = 'EDIT_ORDER_UPDATE_SUCCESS';
export const EDIT_ORDER_UPDATE_ERROR = 'EDIT_ORDER_UPDATE_ERROR';

export const LIST_ORDER_UPDATE = 'LIST_ORDER_UPDATE';
export const LIST_ORDER_UPDATE_SUCCESS = 'LIST_ORDER_UPDATE_SUCCESS';
export const LIST_ORDER_UPDATE_ERROR = 'LIST_ORDER_UPDATE_ERROR';


export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


