import * as Constant from './constant';

export const initialState = { totalCount: 0, data: [], loading: false, error: false, drawer: false, invoiceDetail: {}, deleteModal: false };

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_INVOICE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
      };
    case Constant.LIST_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: action.payload.total,
        data: action.payload.invoices,
        error: false,
      };
    case Constant.LIST_INVOICE_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };
    case Constant.INVOICE_DETAIL:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDetail: action.payload,
        error: false,
      };
    case Constant.INVOICE_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        invoiceDetail: {},
        error: true,
      };
    case Constant.ADD_INVOICE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ADD_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.ADD_INVOICE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.TOGGLE_DRAWER:
      return {
        ...state,
        drawer: !state.drawer,
      };
    case Constant.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !state.deleteModal,
      };
    default:
      return state;
  }
};

export default invoiceReducer;
