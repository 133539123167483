export const ProductCartTableConfig = () => {
  return [
    {
      title: 'Product Name',
      dataIndex: 'productname',
      key: 'productname',
      width: '30%',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '30%',
    },

    {
      title: 'Price',
      dataIndex: 'startingprice',
      key: 'startingprice',
      width: '30%',
    },
  ];
};
