import { Input, Button, Space, Tag } from 'antd';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const LinodeServerTableConfig = (handleSearch, handleReset, responsibilities) => {
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
                backgroundColor: '#32d193',
                borderColor: '#32d193',
                color: 'white',
              }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    };
  };
  return [
    {
      title: 'Instance ID',
      dataIndex: 'instance_id',
      key: 'instance_id',
      width: '3%',
    },
    // {
    //   title: 'Server ID',
    //   dataIndex: 'server_id',
    //   key: 'server_id',
    //   width: '3%',
    // },
    {
      title: 'Name',
      dataIndex: 'client_name',
      key: 'vendor',
      width: '5%',
      ...getColumnSearchProps('client_name'),
    },
    {
      title: 'Server FQDN',
      dataIndex: 'server_fqdn',
      key: 'server_fqdn',
      width: '8%',
    },
    {
      title: 'Server Size',
      dataIndex: 'server_size',
      key: 'server_size',
      width: '5%',
    },
    {
      title: 'Public IP',
      dataIndex: 'public_ip',
      key: 'public_ip',
      width: '3%',
    },
    {
      title: 'Date',
      dataIndex: 'creation_date',
      key: 'creation_date',
      width: '4%',
      render: (creation_date) => {
        return <div>{creation_date ? creation_date.split('T')[0] : 'N/A'}</div>;
      },
    },
    
    {
      title: 'Time',
      dataIndex: 'creation_time',
      key: 'creation_time',
      width: '4%',
    },

    {
      title: 'Status',
      dataIndex: 'isactive',
      key: 'isactive',
      width: '3%',
      render: (status) => {
        return <Tag color={status ? 'green' : 'red'}>{status ? 'Active' : 'In Active'}</Tag>;
      },
    },
    {
      title: 'Suspend',
      dataIndex: 'issuspended',
      key: 'issuspended',
      width: '3%',
      render: (status) => {
        return <Tag color={status ? 'green' : 'red'}>{status ? 'Active' : 'In Active'}</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '2%',
      render: (item) => {
        return (
          <div>
            <Space size="middle">
              <EyeOutlined style={{ color: '#0D8616', cursor: 'pointer', fontSize: '20px' }} />
              {/* <EditOutlined
            onClick={() => editInvoiceSetting(item)}
            style={{ cursor: 'pointer', fontSize: '20px' }}
          /> */}
            </Space>
          </div>
        );
      },
    },
  ];
};
