import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
// import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import { useInjectSaga } from "utils/injectSaga";
import { authUser } from "pages/AppLogin/action";
import saga from "pages/AppLogin/saga";
import _ from "lodash";
import SessionModal from "components/SessionModal";
import Footer from "./Footer";
// import Loading from "assets/fav.png";
const { Header: AntHeader, Content, Sider } = Layout;

function Main(props) {
  const [visible, setVisible] = useState(false);
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");
  useInjectSaga({ key: "login", saga });

  const userState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  console.log(userState);

  useEffect(() => {
    if (
      _.isEmpty(userState.user) &&
      !_.isNil(localStorage.getItem("access_token"))
    ) {
      dispatch(authUser());
    }
  }, []);
  return (
    <>
      {/* <LoadingOverlay active={userState.loading} spinner={Loading}> */}
      <SessionModal />
      <Layout
        className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
          }`}
      >
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {
            // console.log(collapsed, type);
          }}
          trigger={null}
          width={250}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
            }`}
          style={{ background: sidenavType }}
        >
          <Sidenav
            user={userState.user}
            loading={userState.loading}
            userResponsibilities={userState.userResponsibilities}
          />
        </Sider>
        <Layout>
          <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
            <Header
              onPress={openDrawer}
              name={pathname}
              subName={pathname}
              handleSidenavColor={handleSidenavColor}
              handleSidenavType={handleSidenavType}
              handleFixedNavbar={handleFixedNavbar}
            />
          </AntHeader>

          <Content className="content-ant">{props.children}</Content>
          <Footer />
        </Layout>
      </Layout>
      {/* </LoadingOverlay> */}
    </>
  );
}

export default Main;
