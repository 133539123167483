export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_ERROR = 'ADD_PRODUCT_ERROR';

export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_ERROR = 'EDIT_PRODUCT_ERROR';

export const LIST_PRODUCT_ATTRIBUTE = 'LIST_PRODUCT_ATTRIBUTE';
export const LIST_PRODUCT_ATTRIBUTE_SUCCESS = 'LIST_PRODUCT_ATTRIBUTE_SUCCESS';
export const LIST_PRODUCT_ATTRIBUTE_ERROR = 'LIST_PRODUCT_ATTRIBUTE_ERROR';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';

export const LIST_PRODUCT = 'LIST_PRODUCT';
export const LIST_PRODUCT_SUCCESS = 'LIST_PRODUCT_SUCCESS';
export const LIST_PRODUCT_ERROR = 'LIST_PRODUCT_ERROR';

export const LIST_ACTIVE_PRODUCT = 'LIST_ACTIVE_PRODUCT';
export const LIST_ACTIVE_PRODUCT_SUCCESS = 'LIST_ACTIVE_PRODUCT_SUCCESS';
export const LIST_ACTIVE_PRODUCT_ERROR = 'LIST_ACTIVE_PRODUCT_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';

export const SELECTED_PRODUCT_ATTRIBUTES = 'SELECTED_PRODUCT_ATTRIBUTES';

export const REMOVE_SELECTED_PRODUCT_ATTRIBUTES = 'REMOVE_SELECTED_PRODUCT_ATTRIBUTES';
export const REMOVE_SELECTED_PRODUCT_ATTRIBUTES_SUCCESS =
  'REMOVE_SELECTED_PRODUCT_ATTRIBUTES_SUCCESS';

