import { Input, Button, Space, Tag, Checkbox } from 'antd';
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const ProductTableConfig = (handleSearch, handleReset, editProduct) => {
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        if (_.isEqual(dataIndex, 'productname')) {
          return (
            <div style={{ padding: 8 }}>
              <Input
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => handleReset(clearFilters)}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
              </Space>
            </div>
          );
        } else {
          return (
            <div style={{ padding: 18 }}>
              <Checkbox
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys([e.target.checked])}
              >
                {_.isEqual(dataIndex, 'isactive') ? 'Active' : 'Featured'}
              </Checkbox>
              <br />
              <br />
              <Space>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => handleReset(clearFilters)}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
              </Space>
            </div>
          );
        }
      },
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    };
  };
  return [
    {
      title: 'ID',
      dataIndex: 'productid',
      key: 'productid',
      width: '2%',

    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'vendor',
      width: '20%',
      ...getColumnSearchProps('category'),

    },
    {
      title: 'Name',
      dataIndex: 'productname',
      key: 'vendor',
      width: '20%',
      ...getColumnSearchProps('productname'),
    },
    {
      title: 'price',
      dataIndex: 'startingprice',
      key: 'startingprice',
      width: '5%',
    },
    {
      title: 'Featured',
      dataIndex: 'isfeatured',
      key: 'isfeatured',
      width: '5%',
      ...getColumnSearchProps('isfeatured'),
      render: (isfeatured) => {
        return (
          <Tag color={isfeatured ? 'green' : 'blue'}>
            {isfeatured ? 'Featured' : 'Not featured'}
          </Tag>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'isactive',
      key: 'isactive',
      width: '5%',
      // ...getColumnSearchProps('isactive'),
      render: (status) => {
        return <Tag color={status ? 'green' : 'red'}>{status ? 'Active' : 'In Active'}</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '2%',
      render: (item) => {
        return (
          <div>
            <Space size="middle">
              <EditOutlined
                style={{ color: '#0D4B86', cursor: 'pointer', fontSize: '20px' }}
                onClick={() => editProduct(item)}
              />
            </Space>
          </div>
        );
      },
    },
  ];
};
