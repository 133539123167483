import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { ADD_SMTP, EDIT_SMTP, LIST_SMTP, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listSmtp as listSmtpAction, listSmtpSuccess, listSmtpError, addSmtpSuccess, addSmtpError, editSmtpSuccess, editSmtpError, toggleDrawer, toggleDeleteModal } from './action';
import { addAddonSMTP, editAddonSMTP, listAddonSMTP } from 'services/addon';

export function* listSmtpSaga(action) {
  try {
    const res = yield call(listAddonSMTP, action.payload);
    const smtpData = JSON.parse(res.text);
    // console.log(smtpData);
    yield put(
      listSmtpSuccess({
        total: smtpData.smtp.total,
        attributes: smtpData.smtp.smtp.map((item) => ({
          ...item,
          key: item.id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listSmtpError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listSmtpError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addSmtpSaga(action) {
  try {
    const res = yield call(addAddonSMTP, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addSmtpSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listSmtpAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addSmtpError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addSmtpError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addSmtpError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editSmtpSaga(action) {
  try {
    const res = yield call(editAddonSMTP, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editSmtpSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listSmtpAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editSmtpError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editSmtpError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editSmtpError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}
export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* SmtpSaga() {
  yield takeLatest(LIST_SMTP, listSmtpSaga);
  yield takeEvery(ADD_SMTP, addSmtpSaga);
  yield takeEvery(EDIT_SMTP, editSmtpSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
