export const ADD_DISCOUNT = 'ADD_DISCOUNT';
export const ADD_DISCOUNT_SUCCESS = 'ADD_DISCOUNT_SUCCESS';
export const ADD_DISCOUNT_ERROR = 'ADD_DISCOUNT_ERROR';

export const EDIT_DISCOUNT = 'EDIT_DISCOUNT';
export const EDIT_DISCOUNT_SUCCESS = 'EDIT_DISCOUNT_SUCCESS';
export const EDIT_DISCOUNT_ERROR = 'EDIT_DISCOUNT_ERROR';

export const LIST_DISCOUNT = 'LIST_DISCOUNT';
export const LIST_DISCOUNT_SUCCESS = 'LIST_DISCOUNT_SUCCESS';
export const LIST_DISCOUNT_ERROR = 'LIST_DISCOUNT_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';
