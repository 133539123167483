import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { ADD_ORDER_CANCEL, LIST_ORDER_CANCEL, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listOrderCancel as listOrderCancelAction, listOrderCancelSuccess, listOrderCancelError, addOrderCancelSuccess, addOrderCancelError, toggleDrawer, toggleDeleteModal } from './action';
import { cancelOrder, getcancelOrder } from 'services/order';

export function* listOrderCancelSaga(action) {
  try {
    const res = yield call(getcancelOrder, action.payload);
    const UpdateOrderData = JSON.parse(res.text);
    console.log(UpdateOrderData)
    yield put(
      listOrderCancelSuccess({
        total: UpdateOrderData.cancelorders.total,
        attributes: UpdateOrderData.cancelorders.cancelorders.map((item) => ({
          ...item,
          key: item.order_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listOrderCancelError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listOrderCancelError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addOrderCancelSaga(action) {
  try {
    const res = yield call(cancelOrder, action.payload);
    const userData = JSON.parse(res.text);
    // console.log(userData)
    yield put(addOrderCancelSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listOrderCancelAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addOrderCancelError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addOrderCancelError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addOrderCancelError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}
export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* OrderCancelSaga() {
  yield takeLatest(LIST_ORDER_CANCEL, listOrderCancelSaga);
  yield takeEvery(ADD_ORDER_CANCEL, addOrderCancelSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
