import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const listClient = async ({ limit, page }) => {
  return await superagent
    .get(`${BASE_URL}/product/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .query({ page })
    .query({ limit });
};

export const getClientStats = async () => {
  return await superagent
    .get(`${BASE_URL}/client/stats`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json');
};
export const activeClient = async () => {
  return await superagent
    .get(`${BASE_URL}/client/active`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json');
};
export const activeProduct = async () => {
  return await superagent
    .get(`${BASE_URL}/product/active`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json');
};
export const getActiveOrder = async (payload) => {
  return await superagent
    .get(`${BASE_URL}/order/active`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};