import { memo } from "react";
import { Result } from "antd";

function Unauthorized() {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
    />
  );
}

export default memo(Unauthorized);
