import * as Constant from './constant';

export const addAttribute = (payload) => {
  return {
    type: Constant.ADD_ATTRIBUTE,
    payload,
  };
};

export const addAttributeSuccess = () => {
  return {
    type: Constant.ADD_ATTRIBUTE_SUCCESS,
  };
};

export const addAttributeError = () => {
  return {
    type: Constant.ADD_ATTRIBUTE_ERROR,
  };
};

export const editAttribute = (payload) => {
  return {
    type: Constant.EDIT_ATTRIBUTE,
    payload,
  };
};

export const editAttributeSuccess = () => {
  return {
    type: Constant.EDIT_ATTRIBUTE_SUCCESS,
  };
};

export const editAttributeError = () => {
  return {
    type: Constant.EDIT_ATTRIBUTE_ERROR,
  };
};

export const listAttribute = (payload) => {
  return {
    type: Constant.LIST_ATTRIBUTE,
    payload,
  };
};

export const listAttributeSuccess = (payload) => {
  return {
    type: Constant.LIST_ATTRIBUTE_SUCCESS,
    payload,
  };
};

export const listAttributeError = () => {
  return {
    type: Constant.LIST_ATTRIBUTE_ERROR,
  };
};

export const deleteAttribute = () => {
  return {
    type: Constant.DELETE_ATTRIBUTE,
  };
};

export const deleteAttributeSuccess = () => {
  return {
    type: Constant.DELETE_ATTRIBUTE_SUCCESS,
  };
};

export const deleteAttributeError = () => {
  return {
    type: Constant.DELETE_ATTRIBUTE_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
