import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import { CategoryTableConfig } from './CategoryTableConfig';
import { useSelector, useDispatch } from 'react-redux';
import reducer, { initialState } from './reducer';
import AddCategoryDrawer from 'components/AddCategoryDrawer';
import saga from './saga';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { listCategory, toggleDrawer, toggleDeleteModal } from './action';
import _ from 'lodash';

function Category() {
  useInjectReducer({ key: 'category', reducer });
  useInjectSaga({ key: 'category', saga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categoryState = useSelector((state) => state.category || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = CategoryTableConfig(
      handleSearch,
      handleReset,
      onDelete,
      editProductCategory,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listCategory());
  }, []);

  const onDelete = (item) => {
    // if (!_.isNil(item)) {
    //   setSelectedRole(item);
    //   dispatch(toggleDeleteModal());
    //   return;
    // }
    // dispatch(deleteRole(selectedRole.roleid));
  };

  const editProductCategory = (category) => {
    setSelectedCategory(category);
    dispatch(toggleDrawer());
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  return (
    <Card
      title="Categories"
      extra={
        <AddCategoryDrawer
          initialState={initialState}
          selectedCategory={selectedCategory}
          editProductCategory={editProductCategory}
          roleAddResponsibility={_.includes(
            authState.userResponsibilities,
            'PRODUCT_CATEGORY_ADD_BUTTON',
          )}
        />
      }
    >
      <Table
        columns={columns}
        loading={categoryState.loading}
        bordered
        pagination={false}
        size={'small'}
        dataSource={categoryState.data}
        scroll={{ y: 450 }}
      />
    </Card>
  );
}

export default Category;
