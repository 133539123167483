export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const PAYMENT_METHOD = ['Pay Pal','Cash', 'Bank Trasnfer', 'Credit Card',  'Pay Pro'];
export const SUBSCRIPTION_PERIOD = [
  
  '1 month',
  'N/A',
  '3 month (Quarterly)',
  'N/A',
  'N/A',
  '6 month (Bi Annually)',
  'N/A',
  'N/A',
  'N/A',
  'N/A',
  'N/A',
  '12 month (Yearly)',
];
