import React, { useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";
import LoginLogo from "assets/login_logo.png";
import { loginUser } from "./action";
import { useSelector, useDispatch } from "react-redux";
import { useInjectSaga } from "utils/injectSaga";
import saga from "./saga";
import _ from "lodash";
import "./login.css";

const AppLogin = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const userState = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const tokenCheck = localStorage.getItem("access_token");
    if (!_.isNil(tokenCheck)) {
      history.push("/dashboard");
    }
  }, []);

  const loginAppUser = () => {
    form.validateFields().then((values) => {
      dispatch(loginUser({ ...values, history }));
    });
  };

  useInjectSaga({ key: "login", saga });

  return (
    <div className="containers">
      <div className="left-side">
        <div className="form-logo">
          <a href="/">
            <img src={LoginLogo} alt="logo" />
          </a>
        </div>
        <div className="form-text">
          <h3>Login To Your Account</h3>
          <p>Thank you for get back. </p>
        </div>
        <div className="login-form">
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              maxWidth: "100%",
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input placeholder="Email" required />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password placeholder="Password" required />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 24,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                onClick={loginAppUser}
                loading={userState.loading}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="right-side">
        <div className="rightwork">
          <div className="rightlogo">
            {/* <img src={skywhite} alt="" /> */}
          </div>
          {/* <p>
            Customers don't care how much you know until they know how much you
            care. Remember, a happy customer is our best advertisement!
          </p> */}
          <p>
            A happy customer is our best advertisement!
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(AppLogin);
