
import * as Constant from './constant';

export const addSatisfaction = (payload) => {
  return {
    type: Constant.ADD_SATISFACTION,
    payload,
  };
};

export const addSatisfactionSuccess = () => {
  return {
    type: Constant.ADD_SATISFACTION_SUCCESS,
  };
};

export const addSatisfactionError = () => {
  return {
    type: Constant.ADD_SATISFACTION_ERROR,
  };
};

export const editSatisfaction = (payload) => {
  return {
    type: Constant.EDIT_SATISFACTION,
    payload,
  };
};

export const editSatisfactionSuccess = () => {
  return {
    type: Constant.EDIT_SATISFACTION_SUCCESS,
  };
};

export const editSatisfactionError = () => {
  return {
    type: Constant.EDIT_SATISFACTION_ERROR,
  };
};

export const listSatisfaction = (payload) => {
  return {
    type: Constant.LIST_SATISFACTION,
    payload,
  };
};

export const listSatisfactionSuccess = (payload) => {
  return {
    type: Constant.LIST_SATISFACTION_SUCCESS,
    payload,
  };
};

export const listSatisfactionError = () => {
  return {
    type: Constant.LIST_SATISFACTION_ERROR,
  };
};
export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
