import { call, put, takeEvery } from "redux-saga/effects";
import { message } from "antd";
import _ from "lodash";
import { authUserError } from "pages/AppLogin/action";
import { getClientStats } from "services/client";
import { CLIENT_STATS } from "./constant";
import { clientStatsSuccess, clientStatsError } from "./action";

export function* clientStatsSaga(action) {
  try {
    const res = yield call(getClientStats);
    const clientStatsData = JSON.parse(res.text);
    // console.log(clientStatsData)
    yield put(clientStatsSuccess(clientStatsData.data));
  } catch (err) {
    // console.log(err);
    if (_.isEqual(err.status, 401)) {
      yield put(clientStatsError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(clientStatsError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(clientStatsError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error("Something went wrong");
    }
  }
}

export default function* roleSaga() {
  yield takeEvery(CLIENT_STATS, clientStatsSaga);
}
