import * as Constant from './constant';

export const addTestimonial = (payload) => {
  return {
    type: Constant.ADD_TESTIMONIAL,
    payload,
  };
};

export const addTestimonialSuccess = () => {
  return {
    type: Constant.ADD_TESTIMONIAL_SUCCESS,
  };
};

export const addTestimonialError = () => {
  return {
    type: Constant.ADD_TESTIMONIAL_ERROR,
  };
};

export const editTestimonial = (payload) => {
  return {
    type: Constant.EDIT_TESTIMONIAL,
    payload,
  };
};

export const editTestimonialSuccess = () => {
  return {
    type: Constant.EDIT_TESTIMONIAL_SUCCESS,
  };
};

export const editTestimonialError = () => {
  return {
    type: Constant.EDIT_TESTIMONIAL_ERROR,
  };
};

export const listTestimonial = (payload) => {
  return {
    type: Constant.LIST_TESTIMONIAL,
    payload,
  };
};

export const listTestimonialSuccess = (payload) => {
  return {
    type: Constant.LIST_TESTIMONIAL_SUCCESS,
    payload,
  };
};

export const listTestimonialError = () => {
  return {
    type: Constant.LIST_TESTIMONIAL_ERROR,
  };
};
export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
