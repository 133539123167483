import React, { useEffect, useState } from 'react';
import { Card, Table, Pagination, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import _ from 'lodash';
import { toggleDrawer, toggleDeleteModal, listShared } from './action';
import clientReducer, { initialState as clientInitialState } from 'pages/Client/reducer';
import clientSaga from 'pages/Client/saga';
import productSaga from 'pages/Product/saga';
import productReducer, { initialState as productInitialState } from 'pages/Product/reducer';
import { SharedTableConfig } from './SharedTableConfig';
import AddSharedDrawer from 'components/AddSharedDrawer';
import orderReducer, { initialState as orderInitialState } from 'pages/Orders/reducer';
import orderSaga from 'pages/Orders/saga';
function SharedDetails() {
  useInjectReducer({ key: 'shared', reducer });
  useInjectSaga({ key: 'shared', saga });
  useInjectReducer({ key: 'product', reducer: productReducer });
  useInjectSaga({ key: 'product', saga: productSaga });
  useInjectReducer({ key: 'client', reducer: clientReducer });
  useInjectSaga({ key: 'client', saga: clientSaga });
  useInjectReducer({ key: 'order', reducer: orderReducer });
  useInjectSaga({ key: 'order', saga: orderSaga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedShared, setselectedShared] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const sharedState = useSelector((state) => state.shared || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = SharedTableConfig(
      handleSearch,
      handleReset,
      editUserShared,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listShared({ page: 1, limit: 10 }));
  }, []);

  const resetPagination = () => setCurrentPage(1);

  const editUserShared = (email) => {
    setselectedShared(email);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    let filter = { page, limit: pageSize };
    setCurrentPage(page);
    if ((searchedColumn, searchText)) {
      filter = { ...filter, [searchedColumn]: searchText };
    }
    dispatch(listShared(filter));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys.length <= 0) {
      message.error('Please select to search');
      return;
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    dispatch(listShared({ page: 1, limit: 10, [dataIndex]: selectedKeys[0] }));
    resetPagination();
  };
  // const handleDeleteModalToggle = () => dispatch(toggleDeleteModal());

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(null);
    setSearchedColumn(null);
    dispatch(listShared({ page: 1, limit: 10 }));
    resetPagination();
  };
  return (
    <>
      <Card
        title="Manage Shared Server"
        extra={
          <AddSharedDrawer
            initialState={initialState}
            clientInitialState={clientInitialState}
            productInitialState={productInitialState}
            orderInitialState={orderInitialState}
            selectedShared={selectedShared}
            resetPagination={resetPagination}
            editUserShared={editUserShared}
            roleAddResponsibility={_.includes(authState.userResponsibilities, 'ADD_SHARED')}
          />
        }
      >
        <Table
          columns={columns}
          loading={sharedState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={sharedState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={sharedState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={sharedState.loading}
          onChange={onPageChange}
        />
      </Card>
      {/* <ConfirmModal
        title={'Delete Portfolio Confirmation'}
        text={'Are you sure you want to delete this portfolio ?'}
        toggle={sharedState.deleteModal}
        loading={sharedState.loading}
        handleDeleteModalToggle={handleDeleteModalToggle}
        onDelete={onDelete}
      /> */}
    </>
  );
}

export default SharedDetails;
