import * as Constant from './constant';

export const initialState = { totalCount: 0, data: [], contactUser: [], loading: false, error: false };

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_CONTACT:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
      };
    case Constant.LIST_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.contacts,
        error: false,
      };
    case Constant.LIST_CONTACT_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };
    default:
      return state;
  }
};

export default contactReducer;
