export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';

export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_ERROR = 'EDIT_CATEGORY_ERROR';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

export const LIST_CATEGORY = 'LIST_CATEGORY';
export const LIST_CATEGORY_SUCCESS = 'LIST_CATEGORY_SUCCESS';
export const LIST_CATEGORY_ERROR = 'LIST_CATEGORY_ERROR';

export const ACTIVE_CATEGORY = 'ACTIVE_CATEGORY';
export const ACTIVE_CATEGORY_SUCCESS = 'ACTIVE_CATEGORY_SUCCESS';
export const ACTIVE_CATEGORY_ERROR = 'ACTIVE_CATEGORY_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';
