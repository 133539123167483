import { call, put, takeEvery, takeLatest, apply } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { LIST_SUBSCRIPTION } from './constant';
import { listSubscription as listSubscriptionAction, listSubscriptionSuccess, listSubscriptionError } from './action';
import { listSubscription } from 'services/subscription';

export function* listSubscriptionSaga(action) {
  try {
    const res = yield call(listSubscription, action.payload);
    const subscriptionData = JSON.parse(res.text);
    // console.log(subscriptionData)
    yield put(
      listSubscriptionSuccess({
        // total: subscriptionData.logs.total,
        subscriptionreports: subscriptionData.subs.subs.map((item) => ({
          ...item,
          key: item.client_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listSubscriptionError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listSubscriptionError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export default function* SubscriptionSaga() {
  yield takeLatest(LIST_SUBSCRIPTION, listSubscriptionSaga);
}
