import * as Constant from './constant';

export const initialState = { data: [], loading: false, error: false, drawer: false, deleteModal: false };

const userRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_ROLES:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
      };
    case Constant.LIST_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
      };
    case Constant.LIST_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };
    case Constant.ADD_ROLE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ADD_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.ADD_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.EDIT_ROLE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.EDIT_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.EDIT_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.DELETE_ROLE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.DELETE_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.TOGGLE_DRAWER:
      return {
        ...state,
        drawer: !state.drawer,
      };
    case Constant.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !state.deleteModal,
      };
    default:
      return state;
  }
};

export default userRoleReducer;