import { Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const SelectedAddonsConfig = (removeAttribute) => {
  return [
    {
      title: 'ID',
      dataIndex: 'attributeid',
      key: 'attributeid',
      width: '5%',
    },
    {
      title: 'Attribute Name',
      dataIndex: 'attributename',
      key: 'attributename',
      width: '70%',
    },
    {
      title: 'Unit Price',
      dataIndex: 'unit_price',
      key: 'unit_price',
      width: '30%',
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '5%',
      render: (item) => (
        <div>
          <Space size="middle">
            <DeleteOutlined
              style={{ color: '#F52A0E', cursor: 'pointer', fontSize: '20px' }}
              onClick={() => removeAttribute(item)}
            />
          </Space>
        </div>
      ),
    },
  ];
};
