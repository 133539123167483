import React, { useEffect, memo } from 'react';
import { Form, Button, Drawer, Col, Row, Select, Skeleton, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { activeCategory } from 'pages/Category/action';
import { activeAllClient } from 'pages/Client/action';
import { addSmtp, editSmtp } from 'pages/Addons/AddSMTP/action';
import { activeAllOrder } from 'pages/Orders/action';
const { Option } = Select;

function AddAddonSMTPDrawer({ initialState, categoryInitialState, orderInitialState, clientInitialState, selectedSmtp, editUserSmtp, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const orderState = useSelector((state) => state.order || orderInitialState);
  const clientState = useSelector((state) => state.client || clientInitialState);
  const categoryState = useSelector((state) => state.category || categoryInitialState);
  const smtpState = useSelector((state) => state.smtp || initialState);
  const [form] = Form.useForm();

  const toggleSmtpDrawer = (open = false) => {
    form.resetFields();
    editUserSmtp(null);
  };
  if (!_.isNil(selectedSmtp)) {
    form.setFieldsValue({
      smtphost: selectedSmtp.smtphost,
      smtpusername: selectedSmtp.smtpusername,
      smtppassword: selectedSmtp.smtppassword,
      url: selectedSmtp.url,
      categoryid: selectedSmtp.categoryid,
      order_id: selectedSmtp.order_id,
      client_id: selectedSmtp.client_id,
      isactive: selectedSmtp.isactive,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }
  useEffect(() => {
    if (smtpState.drawer) {
      dispatch(activeAllClient());
    }
  }, [clientState.drawer]);
  useEffect(() => {
    if (smtpState.drawer) {
      dispatch(activeCategory());
    }
  }, [categoryState.drawer]);
  useEffect(() => {
    if (smtpState.drawer) {
      dispatch(activeAllOrder());
    }
  }, [orderState.drawer]);
  const handleSmtp = () => {
    form.validateFields().then((values) => {
      values.isactive = _.isNil(values.isactive) || !values.isactive ? false : true;

      if (selectedSmtp) {
        const { id } = selectedSmtp;
        dispatch(editSmtp({ id, ...values }));
      } else {
        dispatch(addSmtp(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => toggleSmtpDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add SMTP
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedSmtp ? `Edit` : `Add`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleSmtpDrawer()}
        visible={smtpState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggleSmtpDrawer()}
              loading={smtpState.loading}
              disabled={smtpState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSmtp()}
              loading={false}
              disabled={smtpState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedSmtp ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={
            orderState.loading && categoryState.loading && smtpState.loading && clientState.loading
          }
          active={
            orderState.loading && categoryState.loading && smtpState.loading && clientState.loading
          }
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="categoryid"
                  label="Select Category"
                  initialValue={selectedSmtp?.productcategoryid}
                  rules={[
                    {
                      required: true,
                      message: 'Please select category',
                    },
                  ]}
                >
                  <Select placeholder="Select Category" loading={categoryState.loading}>
                    {categoryState.activeCategories.map((cat) => (
                      <Option value={cat.productcatid} key={cat.productcatid}>
                        {cat.productcatname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="client_id"
                  label="Select Client"
                  initialValue={selectedSmtp?.client_id}
                  rules={[
                    {
                      required: true,
                      message: 'Please select client',
                    },
                  ]}
                >
                  <Select placeholder="Select client" loading={clientState.loading}>
                    {clientState.activeallClients.map((cat) => (
                      <Option value={cat.client_id} key={cat.client_id}>
                        {cat.client_name} {cat.last_name} ({cat.client_id})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col span={24}>
                <Form.Item
                  name="order_id"
                  label="Order ID"
                  rules={[{ required: true, message: 'Please enter order id' }]}
                >
                  <Input placeholder="Please enter order id" disabled={smtpState.loading} />
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col span={24}>
                <Form.Item
                  name="order_id"
                  label="Select Order"
                  initialValue={selectedSmtp?.order_id}
                  rules={[
                    {
                      required: true,
                      message: 'Please select order',
                    },
                  ]}
                >
                  <Select placeholder="Select Order" loading={orderState.loading}>
                    {orderState.activeallOrders.map((cat) => (
                      <Option value={cat.order_id} key={cat.order_id}>
                        ({cat.order_id}) {cat.productname} ({cat.client_name})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="smtphost"
                  label="Host"
                  rules={[{ required: true, message: 'Please enter Host' }]}
                >
                  <Input placeholder="Please enter Host" disabled={smtpState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="smtpusername"
                  label="User Name"
                  rules={[{ required: true, message: 'Please enter User name' }]}
                >
                  <Input placeholder="Please enter user name" disabled={smtpState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="smtppassword"
                  label="Password"
                  rules={[{ required: true, message: 'Please enter password' }]}
                >
                  <Input placeholder="Please enter Password" disabled={smtpState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="isactive" label="Status">
                  <Select placeholder="Status" defaultValue={true}>
                    <Option value={true}>Active</Option>
                    <Option value={false}>Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddAddonSMTPDrawer);
