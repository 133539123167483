import React, { memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { addAttribute, editAttribute } from 'pages/Attribute/action';
import _ from 'lodash';
const { Option } = Select;

function AddAttributeDrawer({ initialState, selectedAttribute, editProductAttributes, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const attributeState = useSelector((state) => state.attribute || initialState);
  const [form] = Form.useForm();

  if (!_.isNil(selectedAttribute)) {
    form.setFieldsValue({
      attributename: selectedAttribute.attributename,
      vendor: selectedAttribute.vendor,
      status: _.isEqual(selectedAttribute.status, 1) ? true : false,
    });
  }

  const toggleAttributeDrawer = (open = false) => {
    form.resetFields();
    editProductAttributes(null);
  };

  const handleAttributes = () => {
    form.validateFields().then((values) => {
      if (values && (_.isNil(values.vendor) || _.isEmpty(values.vendor))) values.vendor = 'none';
      if (_.isNil(values.status) || values.status) values.status = 1;
      else values.status = 0;
      if (selectedAttribute) {
        dispatch(editAttribute({ attributeid: selectedAttribute.attributeid, ...values }));
      } else {
        dispatch(addAttribute(values));
      }
      resetPagination();
    });
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => toggleAttributeDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add Attribute
        </Button>
      ) : (
        ''
      )}
      <Drawer
        title={selectedAttribute ? `Edit Attribute` : `Add Attribute`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleAttributeDrawer()}
        visible={attributeState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggleAttributeDrawer()}
              loading={attributeState.loading}
              disabled={attributeState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleAttributes()}
              loading={false}
              disabled={attributeState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedAttribute ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical" initialValues={{ remember: false }}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="attributename"
                label="Attribute Name"
                rules={[{ required: true, message: 'Please enter attribute name' }]}
              >
                <Input
                  placeholder="Please enter attribute name"
                  disabled={attributeState.loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="vendor"
                label="Vendor"
                rules={[{ required: false, message: 'Please enter vendor' }]}
              >
                <Input
                  placeholder="Please enter category description"
                  disabled={attributeState.loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="status" label="status">
                <Select placeholder="Status" defaultValue={true}>
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}

export default memo(AddAttributeDrawer);
