
export const ADD_PROMOTION = 'ADD_PROMOTION';
export const ADD_PROMOTION_SUCCESS = 'ADD_PROMOTION_SUCCESS';
export const ADD_PROMOTION_ERROR = 'ADD_PROMOTION_ERROR';

export const EDIT_PROMOTION = 'EDIT_PROMOTION';
export const EDIT_PROMOTION_SUCCESS = 'EDIT_PROMOTION_SUCCESS';
export const EDIT_PROMOTION_ERROR = 'EDIT_PROMOTION_ERROR';

export const LIST_PROMOTION = 'LIST_PROMOTION';
export const LIST_PROMOTION_SUCCESS = 'LIST_PROMOTION_SUCCESS';
export const LIST_PROMOTION_ERROR = 'LIST_PROMOTION_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


