import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const listCategory = async () => {
  return await superagent
    .get(`${BASE_URL}/category/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json');
};

export const activeCategory = async () => {
  return await superagent
    .get(`${BASE_URL}/category/active`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json');
};

export const addCategory = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/category/create`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};

export const editCategory = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/category/edit`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};