
import * as Constant from './constant';

export const addHowto = (payload) => {
  return {
    type: Constant.ADD_HOWTO,
    payload,
  };
};

export const addHowtoSuccess = () => {
  return {
    type: Constant.ADD_HOWTO_SUCCESS,
  };
};

export const addHowtoError = () => {
  return {
    type: Constant.ADD_HOWTO_ERROR,
  };
};

export const editHowto = (payload) => {
  return {
    type: Constant.EDIT_HOWTO,
    payload,
  };
};

export const editHowtoSuccess = () => {
  return {
    type: Constant.EDIT_HOWTO_SUCCESS,
  };
};

export const editHowtoError = () => {
  return {
    type: Constant.EDIT_HOWTO_ERROR,
  };
};

export const listHowto = (payload) => {
  return {
    type: Constant.LIST_HOWTO,
    payload,
  };
};

export const listHowtoSuccess = (payload) => {
  return {
    type: Constant.LIST_HOWTO_SUCCESS,
    payload,
  };
};

export const listHowtoError = () => {
  return {
    type: Constant.LIST_HOWTO_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
