import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const listUserLogs = async () => {
  return await superagent
    .get(`${BASE_URL}/log/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2);
};
export const listEventLogs = async () => {
  return await superagent
    .get(`${BASE_URL}/event/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2);
};
export const listNotificationLogs = async () => {
  return await superagent
    .get(`${BASE_URL}/notification/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2);
};
