import React, { useState, useEffect, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, Upload, Skeleton, Progress } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { activeCategory } from 'pages/Category/action';
import { addPortfolio, editPortfolio } from 'pages/Portfolios/action';
import axios from 'axios';
const { Option } = Select;

function AddPortfolioDrawer({ initialState, categoryInitialState, selectedPortfolio, editUserPortfolio, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.category || categoryInitialState);
  const portfolioState = useSelector((state) => state.portfolio || initialState);
  const [form] = Form.useForm();
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [progress, setProgress] = useState(0);
  // console.log(categoryState)

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append('image', file);
    try {
      const res = await axios.post('https://jsonplaceholder.typicode.com/posts', fmData, config);
      onSuccess('Ok');
      console.log('server res: ', res);
    } catch (err) {
      console.log('Eroor: ', err);
      const error = new Error('Some error');
      onError({ err });
    }
  };
  const handleOnChange = ({ file, fileList, event }) => {
    console.log(file, fileList, event);
    //Using Hooks to update the state to the current filelist
    setDefaultFileList(fileList);
    //filelist - [{uid: "-1",url:'Some url to image'}]
  };

  const togglePortfolioDrawer = (open = false) => {
    form.resetFields();
    editUserPortfolio(null);
  };
  if (!_.isNil(selectedPortfolio)) {
    form.setFieldsValue({
      portfolio_name: selectedPortfolio.portfolio_name,
      url: selectedPortfolio.url,
      productcategoryid: selectedPortfolio.productcategoryid,
      isenabled: _.isEqual(selectedPortfolio.isenabled, 1) ? true : false,
      ishomepageport: selectedPortfolio?.ishomepageport || false,
      isactive: selectedPortfolio.isactive,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }
  useEffect(() => {
    if (portfolioState.drawer) {
      dispatch(activeCategory());
    }
  }, [categoryState.drawer]);
  const handlePortfolio = () => {
    form.validateFields().then((values) => {
      values.isactive = _.isNil(values.isactive) || !values.isactive ? false : true;

      if (selectedPortfolio) {
        const { portfolio_id } = selectedPortfolio;
        dispatch(editPortfolio({ portfolio_id, ...values }));
      } else {
        dispatch(addPortfolio(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => togglePortfolioDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add Portfolio
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedPortfolio ? `Edit Portfolio` : `Add Portfolio`}
        width={800}
        destroyOnClose={true}
        onClose={() => togglePortfolioDrawer()}
        visible={portfolioState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => togglePortfolioDrawer()}
              loading={portfolioState.loading}
              disabled={portfolioState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handlePortfolio()}
              loading={false}
              disabled={portfolioState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedPortfolio ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={categoryState.loading && portfolioState.loading}
          active={categoryState.loading && portfolioState.loading}
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="productcategoryid"
                  label="Select Category"
                  initialValue={selectedPortfolio?.productcategoryid}
                  rules={[
                    {
                      required: false,
                      message: 'Please select category',
                    },
                  ]}
                >
                  <Select placeholder="Select Category" loading={categoryState.loading}>
                    {categoryState.activeCategories.map((cat) => (
                      <Option value={cat.productcatid} key={cat.productcatid}>
                        {cat.productcatname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="portfolio_name"
                  label="Name"
                  rules={[{ required: false, message: 'Please enter name' }]}
                >
                  <Input placeholder="Please enter name" disabled={portfolioState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="url"
                  label="URL"
                  rules={[{ required: false, message: 'Please enter url' }]}
                >
                  <Input placeholder="Please enter url" disabled={portfolioState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item name="ishomepageport" label="Homepage Portfolio">
                  <Select placeholder="Homepage Portfolio" defaultValue={false}>
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="isactive" label="Status">
                  <Select placeholder="Status" defaultValue={true}>
                    <Option value={true}>Active</Option>
                    <Option value={false}>Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24} style={{ width: '100%' }}>
                <Form.Item
                  name="Picture"
                  label="picture"
                  rules={[{ required: false, message: 'Please uplaod picture' }]}
                >
                  <Upload
                    accept="image/*"
                    customRequest={uploadImage}
                    onChange={handleOnChange}
                    listType="picture-card"
                    defaultFileList={defaultFileList}
                    className="image-upload-grid"
                  >
                    {defaultFileList.length >= 1 ? null : <div>Upload Button</div>}
                  </Upload>
                  {progress > 0 ? <Progress percent={progress} /> : null}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddPortfolioDrawer);
