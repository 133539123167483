
import * as Constant from './constant';

export const addPromotion = (payload) => {
  return {
    type: Constant.ADD_PROMOTION,
    payload,
  };
};

export const addPromotionSuccess = () => {
  return {
    type: Constant.ADD_PROMOTION_SUCCESS,
  };
};

export const addPromotionError = () => {
  return {
    type: Constant.ADD_PROMOTION_ERROR,
  };
};

export const editPromotion = (payload) => {
  return {
    type: Constant.EDIT_PROMOTION,
    payload,
  };
};

export const editPromotionSuccess = () => {
  return {
    type: Constant.EDIT_PROMOTION_SUCCESS,
  };
};

export const editPromotionError = () => {
  return {
    type: Constant.EDIT_PROMOTION_ERROR,
  };
};

export const listPromotion = (payload) => {
  return {
    type: Constant.LIST_PROMOTION,
    payload,
  };
};

export const listPromotionSuccess = (payload) => {
  return {
    type: Constant.LIST_PROMOTION_SUCCESS,
    payload,
  };
};

export const listPromotionError = () => {
  return {
    type: Constant.LIST_PROMOTION_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
