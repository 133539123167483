import React, { useEffect, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { activeAllProducts } from 'pages/Product/action';
import { addDedicated, editDedicated } from 'pages/PurchasedProducts/DedicatedProduct/action';
import { activeAllClient } from 'pages/Client/action';
import { activeAllOrder } from 'pages/Orders/action';
const { Option } = Select;

function AddDedicatedDrawer({ initialState, productInitialState, selectedDedicated, clientInitialState, editUserDedicated, orderInitialState, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const dedicatedState = useSelector((state) => state.dedicated || initialState);
  const clientState = useSelector((state) => state.client || clientInitialState);
  const orderState = useSelector((state) => state.order || orderInitialState);
  const productState = useSelector((state) => state.product || productInitialState);
  const [form] = Form.useForm();

  const toggleDedicatedDrawer = (open = false) => {
    form.resetFields();
    editUserDedicated(null);
  };
  if (!_.isNil(selectedDedicated)) {
    form.setFieldsValue({
      productid: selectedDedicated.productid,
      orderid: selectedDedicated.orderid,
      clientid: selectedDedicated.clientid,
      isactive: selectedDedicated.isactive,
      username: selectedDedicated.username,
      password: selectedDedicated.password,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }
  useEffect(() => {
    if (dedicatedState.drawer) {
      dispatch(activeAllProducts());
    }
  }, [productState.drawer]);
  useEffect(() => {
    if (dedicatedState.drawer) {
      dispatch(activeAllClient());
    }
  }, [clientState.drawer]);
  useEffect(() => {
    if (dedicatedState.drawer) {
      dispatch(activeAllOrder());
    }
  }, [orderState.drawer]);
  const handleDedicated = () => {
    form.validateFields().then((values) => {
      if (selectedDedicated) {
        const { dedicated_id } = selectedDedicated;
        dispatch(editDedicated({ dedicated_id, ...values }));
      } else {
        dispatch(addDedicated(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => toggleDedicatedDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add Dedicated
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedDedicated ? `Edit` : `Add`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleDedicatedDrawer()}
        visible={dedicatedState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggleDedicatedDrawer()}
              loading={dedicatedState.loading}
              disabled={dedicatedState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleDedicated()}
              loading={false}
              disabled={dedicatedState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedDedicated ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={
            orderState.loading &&
            productState.loading &&
            dedicatedState.loading &&
            clientState.loadin
          }
          active={
            orderState.loading &&
            productState.loading &&
            dedicatedState.loading &&
            clientState.loadin
          }
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            {/* <Row>
              <Col span={24}>
                <Form.Item
                  name="orderid"
                  label="Order ID"
                  rules={[{ required: false, message: 'Please enter order id' }]}
                >
                  <Input placeholder="Please enter order id" disabled={dedicatedState.loading} />
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col span={24}>
                <Form.Item
                  name="order_id"
                  label="Select Order"
                  initialValue={selectedDedicated?.order_id}
                  rules={[
                    {
                      required: true,
                      message: 'Please select order',
                    },
                  ]}
                >
                  <Select placeholder="Select Order" loading={orderState.loading}>
                    {orderState.activeallOrders.map((cat) => (
                      <Option value={cat.order_id} key={cat.order_id}>
                        ({cat.order_id}) {cat.productname} ({cat.client_name})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="productid"
                  label="Select Product"
                  initialValue={selectedDedicated?.productid}
                  rules={[
                    {
                      required: true,
                      message: 'Please select Product',
                    },
                  ]}
                >
                  <Select placeholder="Select Product" loading={productState.loading}>
                    {productState.activeallProducts.map((cat) => (
                      <Option value={cat.productid} key={cat.productid}>
                        {cat.productname} ({cat.productid})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="clientid"
                  label="Select Client"
                  initialValue={selectedDedicated?.client_id}
                  rules={[
                    {
                      required: true,
                      message: 'Please select client',
                    },
                  ]}
                >
                  <Select placeholder="Select client" loading={clientState.loading}>
                    {clientState.activeallClients.map((cat) => (
                      <Option value={cat.client_id} key={cat.client_id}>
                        {cat.client_name} {cat.last_name} ({cat.client_id})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="username"
                  label="User Name"
                  rules={[{ required: true, message: 'Please enter User Name' }]}
                >
                  <Input placeholder="Please enter User Name" disabled={dedicatedState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true, message: 'Please enter Password' }]}
                >
                  <Input placeholder="Please enter Password" disabled={dedicatedState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="isactive" label="Status">
                  <Select placeholder="Status" defaultValue={true}>
                    <Option value={true}>Active</Option>
                    <Option value={false}>Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddDedicatedDrawer);
