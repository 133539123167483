import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";

function Footer() {
  const { Footer: AntFooter } = Layout;
  const today = new Date();
  const year = today.getFullYear();
  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className="just" justify="center">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            © {year}, made with
            {<HeartFilled />} by
            <a className="font-weight-bold" target="_blank">
              OPSWAYS
            </a>
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}></Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
