
import { Space, Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import _ from 'lodash';

const InvoiceStatus = ['Unpaid', 'Paid', 'Updated', '', 'Completed', 'Due Date Crossed'];

export const InvoiceTableConfig = (viewInvoice) => {
  return [
    {
      title: 'Invoice ID',
      dataIndex: 'invoice_id',
      key: 'invoice_id',
      width: '2%',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      width: '3%',
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      width: '3%',
      render: (invoice_date) => {
        return <div>{invoice_date ? invoice_date.split('T')[0] : 'N/A'}</div>;
      },
    },
    {
      title: 'Staus',
      dataIndex: 'invoice_status_id',
      key: 'invoice_status_id',
      width: '3%',
      render: (invoice_status_id) => InvoiceStatus[parseInt(invoice_status_id)],
    },
    {
      title: 'Total',
      dataIndex: 'order_total',
      key: 'order_total',
      width: '3%',
    },
    {
      title: 'Pro-Rated',
      dataIndex: 'is_prorated',
      key: 'is_prorated',
      width: '3%',
      render: (is_prorated) => {
        return (
          <div>
            <Tag color={is_prorated ? 'green' : 'blue'}>{is_prorated ? 'Yes' : 'No'}</Tag>
          </div>
        );
      },
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      width: '3%',
      render: (due_date) => {
        return <div>{due_date ? due_date.split('T')[0] : 'N/A'}</div>;
      },
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      width: '3%',
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '2%',
      render: (item) => {
        
        return (
          
          <div>
            
            <Space size="middle">
           
              <EyeOutlined
                onClick={()=> viewInvoice(item)}
                style={{ color: '#0D8616', cursor: 'pointer', fontSize: '20px' }}
              />
            </Space>
          </div>
        );
      },
    },
  ];
};
