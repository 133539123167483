
export const ADD_SMTP = 'ADD_SMTP';
export const ADD_SMTP_SUCCESS = 'ADD_SMTP_SUCCESS';
export const ADD_SMTP_ERROR = 'ADD_SMTP_ERROR';

export const EDIT_SMTP = 'EDIT_SMTP';
export const EDIT_SMTP_SUCCESS = 'EDIT_SMTP_SUCCESS';
export const EDIT_SMTP_ERROR = 'EDIT_SMTP_ERROR';

export const LIST_SMTP = 'LIST_SMTP';
export const LIST_SMTP_SUCCESS = 'LIST_SMTP_SUCCESS';
export const LIST_SMTP_ERROR = 'LIST_SMTP_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


