
export const ADD_HOWTO = 'ADD_HOWTO';
export const ADD_HOWTO_SUCCESS = 'ADD_HOWTO_SUCCESS';
export const ADD_HOWTO_ERROR = 'ADD_HOWTO_ERROR';

export const EDIT_HOWTO = 'EDIT_HOWTO';
export const EDIT_HOWTO_SUCCESS = 'EDIT_HOWTO_SUCCESS';
export const EDIT_HOWTO_ERROR = 'EDIT_HOWTO_ERROR';

export const LIST_HOWTO = 'LIST_HOWTO';
export const LIST_HOWTO_SUCCESS = 'LIST_HOWTO_SUCCESS';
export const LIST_HOWTO_ERROR = 'LIST_HOWTO_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


