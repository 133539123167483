import React, { useEffect, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { addTestimonial, editTestimonial } from 'pages/Testimonials/action';
import { activeAllClient } from 'pages/Client/action';
const { Option } = Select;

function AddTestimonialDrawer({ initialState, clientInitialState, selectedTestimonial, editUserTestimonial, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const clientState = useSelector((state) => state.client || clientInitialState);
  const testimonialState = useSelector((state) => state.testimonial || initialState);
  const [form] = Form.useForm();

  const toggleTestimonialDrawer = (open = false) => {
    form.resetFields();
    editUserTestimonial(null);
  };
  if (!_.isNil(selectedTestimonial)) {
    form.setFieldsValue({
      client_id: selectedTestimonial.client_id,
      client_website: selectedTestimonial.client_website,
      client_testimonials: selectedTestimonial.client_testimonials,
      isenabled: _.isEqual(selectedTestimonial.isenabled, 1) ? true : false,
      isactive: selectedTestimonial.isactive,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }
  useEffect(() => {
    if (testimonialState.drawer) {
      dispatch(activeAllClient());
    }
  }, [clientState.drawer]);
  const handleTestimonial = () => {
    form.validateFields().then((values) => {
      values.isactive = _.isNil(values.isactive) || !values.isactive ? false : true;

      if (selectedTestimonial) {
        const { testimonial_id } = selectedTestimonial;
        dispatch(editTestimonial({ testimonial_id, ...values }));
      } else {
        dispatch(addTestimonial(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => toggleTestimonialDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add Testimonial
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedTestimonial ? `Edit Testimonial` : `Add Testimonial`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleTestimonialDrawer()}
        visible={testimonialState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggleTestimonialDrawer()}
              loading={testimonialState.loading}
              disabled={testimonialState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleTestimonial()}
              loading={false}
              disabled={testimonialState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedTestimonial ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={clientState.loading && testimonialState.loading}
          active={clientState.loading && testimonialState.loading}
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="client_id"
                  label="Select Client"
                  initialValue={selectedTestimonial?.client_id}
                  rules={[
                    {
                      required: false,
                      message: 'Please select client',
                    },
                  ]}
                >
                  <Select placeholder="Select client" loading={clientState.loading}>
                    {clientState.activeallClients.map((cat) => (
                      <Option value={cat.client_id} key={cat.client_id}>
                        {cat.client_name} {cat.last_name} ({cat.client_id})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="client_website"
                  label="Client Website"
                  rules={[{ required: false, message: 'Please enter website' }]}
                >
                  <Input placeholder="Please enter website" disabled={testimonialState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="client_testimonials"
                  label="Client Testimonials"
                  rules={[{ required: true, message: 'Please enter testimonials' }]}
                >
                  <Input.TextArea
                    placeholder="Please enter testimonials"
                    rows={4}
                    allowClear={true}
                    disabled={testimonialState.loading}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="isactive" label="Status">
                  <Select placeholder="Status" defaultValue={true}>
                    <Option value={true}>Active</Option>
                    <Option value={false}>Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddTestimonialDrawer);
