import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { ADD_DEDICATED, EDIT_DEDICATED, LIST_DEDICATED, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listDedicated as listDedicatedAction, listDedicatedSuccess, listDedicatedError, addDedicatedSuccess, addDedicatedError, editDedicatedSuccess, editDedicatedError, toggleDrawer, toggleDeleteModal } from './action';
import { addDedicated, editDedicated, listDedicated } from 'services/linode';

export function* listDedicatedSaga(action) {
  try {
    const res = yield call(listDedicated, action.payload);
    const dnsData = JSON.parse(res.text);
    // console.log(dnsData);
    yield put(
      listDedicatedSuccess({
        total: dnsData.dedicated.total,
        attributes: dnsData.dedicated.dedicated.map((item) => ({
          ...item,
          key: item.dedicated_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listDedicatedError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listDedicatedError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addDedicatedSaga(action) {
  try {
    const res = yield call(addDedicated, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addDedicatedSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listDedicatedAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addDedicatedError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addDedicatedError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addDedicatedError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editDedicatedSaga(action) {
  try {
    const res = yield call(editDedicated, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editDedicatedSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listDedicatedAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editDedicatedError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editDedicatedError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editDedicatedError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}
export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* DedicatedSaga() {
  yield takeLatest(LIST_DEDICATED, listDedicatedSaga);
  yield takeEvery(ADD_DEDICATED, addDedicatedSaga);
  yield takeEvery(EDIT_DEDICATED, editDedicatedSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
