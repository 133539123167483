import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const addInvoice = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/invoice/create`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2)
    .send(payload);
};

export const listInvoice = async ({ page, limit }) => {
  return await superagent
    .get(`${BASE_URL}/invoice/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .query({ page })
    .query({ limit })
    .retry(2);
};
export const editInvoice = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/invoice/edit`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};
export const deleteInvoice = async (payload) => {
  return await superagent
    .delete(`${BASE_URL}/invoice/delete`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};

export const listInvoiceSettings = async (paramType) => {
  return await superagent
    .get(`${BASE_URL}/miscellaneous/genparams`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .query(paramType)
    .retry(2);
};

export const editInvoiceSetting = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/miscellaneous/genparams`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .send(payload);
};

export const getInvoiceDetail = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/invoice/detail`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .send(payload);
};


export const monthlySalesCount = async () => {
  return await superagent
    .get(`${BASE_URL}/invoice/salescount`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2);
};