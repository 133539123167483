import _ from 'lodash';
import * as Constants from './constant';

const initialState = { user: {}, loading: false, error: false, isLoggedIn: false, userResponsibilities: [] };

const loginUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.USER_LOGIN: {
      return {
        user: {},
        loading: true,
        isLoggedIn: false,
        error: false,
        userResponsibilities: [],
      };
    }
    case Constants.USER_LOGIN_SUCCESS: {
      return {
        user: action.payload,
        loading: false,
        isLoggedIn: true,
        error: false,
        userResponsibilities: _.map(action.payload.userResponsibilities, 'responsibilityname'),
      };
    }
    case Constants.USER_LOGIN_ERROR: {
      return {
        user: {},
        loading: false,
        isLoggedIn: false,
        error: true,
        userResponsibilities: [],
      };
    }
    case Constants.USER_LOGOUT: {
      return {
        user: {},
        loading: false,
        isLoggedIn: false,
        error: false,
      };
    }
    case Constants.GET_AUTH_USER: {
      return {
        user: {},
        loading: true,
        isLoggedIn: false,
        error: false,
        userResponsibilities: [],
      };
    }
    case Constants.GET_AUTH_USER_SUCCESS: {
      return {
        user: action.payload,
        loading: false,
        isLoggedIn: true,
        error: false,
        userResponsibilities: _.map(action.payload.userResponsibilities, 'responsibilityname'),
      };
    }
    case Constants.GET_AUTH_USER_ERROR: {
      return {
        user: {},
        loading: false,
        isLoggedIn: false,
        error: true,
        userResponsibilities: [],
      };
    }
    default:
      return state;
  }
};

export default loginUserReducer;
