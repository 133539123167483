import * as Constant from './constant';

export const listCategory = () => {
  return {
    type: Constant.LIST_CATEGORY,
  };
};

export const listCategorySuccess = (payload) => {
  return {
    type: Constant.LIST_CATEGORY_SUCCESS,
    payload,
  };
};

export const listCategoryError = () => {
  return {
    type: Constant.LIST_CATEGORY_ERROR,
  };
};

export const activeCategory = () => {
  return {
    type: Constant.ACTIVE_CATEGORY,
  };
};

export const activeCategorySuccess = (payload) => {
  return {
    type: Constant.ACTIVE_CATEGORY_SUCCESS,
    payload,
  };
};

export const activeCategoryError = () => {
  return {
    type: Constant.ACTIVE_CATEGORY_ERROR,
  };
};

export const addCategory = (payload) => {
  return {
    type: Constant.ADD_CATEGORY,
    payload,
  };
};

export const addCategorySuccess = (payload) => {
  return {
    type: Constant.ADD_CATEGORY_SUCCESS,
    payload,
  };
};

export const addCategoryError = () => {
  return {
    type: Constant.ADD_CATEGORY_ERROR,
  };
};

export const editCategory = (payload) => {
  return {
    type: Constant.EDIT_CATEGORY,
    payload,
  };
};

export const editCategorySuccess = (payload) => {
  return {
    type: Constant.EDIT_CATEGORY_SUCCESS,
    payload,
  };
};

export const editCategoryError = () => {
  return {
    type: Constant.EDIT_CATEGORY_ERROR,
  };
};

export const deleteCategory = (payload) => {
  return {
    type: Constant.DELETE_CATEGORY,
    payload,
  };
};

export const deleteCategorySuccess = (payload) => {
  return {
    type: Constant.DELETE_CATEGORY_SUCCESS,
    payload,
  };
};

export const deleteCategoryError = () => {
  return {
    type: Constant.DELETE_CATEGORY_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
