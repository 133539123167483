import React, { useState, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { addHowto, editHowto } from 'pages/Howto/action';
const { Option } = Select;

function AddHowtoDrawer({ initialState, selectedHowto, editUserHowto, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const howtoState = useSelector((state) => state.howto || initialState);
  const [form] = Form.useForm();
  // const [defaultFileList, setDefaultFileList] = useState([]);
  // const [progress, setProgress] = useState(0);

  const toggleHowtoDrawer = (open = false) => {
    form.resetFields();
    editUserHowto(null);
  };
  if (!_.isNil(selectedHowto)) {
    form.setFieldsValue({
      message: selectedHowto.message,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }

  const handleHowto = () => {
    form.validateFields().then((values) => {
      values.isactive = _.isNil(values.isactive) || !values.isactive ? false : true;

      if (selectedHowto) {
        const { howtoid } = selectedHowto;
        dispatch(editHowto({ howtoid, ...values }));
      } else {
        dispatch(addHowto(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => toggleHowtoDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add How to
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedHowto ? `Edit How to` : `Add How to`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleHowtoDrawer()}
        visible={howtoState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggleHowtoDrawer()}
              loading={howtoState.loading}
              disabled={howtoState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleHowto()}
              loading={false}
              disabled={howtoState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedHowto ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical" initialValues={{ remember: false }}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="howtotitle"
                label="Title"
                rules={[{ required: true, message: 'Please enter Title' }]}
              >
                <Input
                  placeholder="Please enter Title"
                  allowClear={true}
                  disabled={howtoState.loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="howtocontent"
                label="Content"
                rules={[{ required: true, message: 'Please enter Content' }]}
              >
                <Input.TextArea
                  placeholder="Please enter Content"
                  allowClear={true}
                  rows={4}
                  disabled={howtoState.loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="isactive" label="Status">
                <Select placeholder="Status" defaultValue={true}>
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}

export default memo(AddHowtoDrawer);
