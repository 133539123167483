import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { USER_LOGIN, GET_AUTH_USER } from './constant';
import { loginUserSuccess, loginUserError, authUserSuccess, authUserError } from './action';
import { loginAppUser, getAuthUser } from 'services/auth';

export function* loginUser(action) {
  try {
    const obj = action.payload;
    const history = action.payload.history;
    delete obj['history'];
    const res = yield call(loginAppUser, obj);
    const userData = JSON.parse(res.text);
    yield put(loginUserSuccess(userData.data));
    localStorage.setItem('access_token', userData.data.token);
    history.push('/dashboard');
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(loginUserError(err.response.text));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(loginUserError(err.response.text));
    }
  }
}

export function* getAuthenticatedUser(action) {
  try {
    const res = yield call(getAuthUser, action);
    const userData = JSON.parse(res.text);
    yield put(authUserSuccess(userData.data));
    localStorage.setItem('access_token', userData.data.token);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      const serverError = JSON.parse(err.response.text);
    }
    yield put(authUserError({}));
  }
}

export default function* loginSaga() {
  yield takeLatest(USER_LOGIN, loginUser);
  yield takeLatest(GET_AUTH_USER, getAuthenticatedUser);
}
