import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const listContact = async ({ limit, page }) => {
  return await superagent
    .get(`${BASE_URL}/contact/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .query({ page })
    .query({ limit });
};
