
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const ADD_MESSAGE_ERROR = 'ADD_MESSAGE_ERROR';

export const EDIT_MESSAGE = 'EDIT_MESSAGE';
export const EDIT_MESSAGE_SUCCESS = 'EDIT_MESSAGE_SUCCESS';
export const EDIT_MESSAGE_ERROR = 'EDIT_MESSAGE_ERROR';

export const LIST_MESSAGE = 'LIST_MESSAGE';
export const LIST_MESSAGE_SUCCESS = 'LIST_MESSAGE_SUCCESS';
export const LIST_MESSAGE_ERROR = 'LIST_MESSAGE_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


