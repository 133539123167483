import React, { useEffect, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { addOneclick, editOneclick } from 'pages/OneClick/action';
import { activeAllClient } from 'pages/Client/action';
const { Option } = Select;

function AddOneClickDrawer({ initialState, clientInitialState, selectedOneclick, editUserOneclick, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const clientState = useSelector((state) => state.client || clientInitialState);
  const oneclickState = useSelector((state) => state.oneclick || initialState);
  const [form] = Form.useForm();

  const toggelOneclickDrawer = (open = false) => {
    form.resetFields();
    editUserOneclick(null);
  };
  if (!_.isNil(selectedOneclick)) {
    form.setFieldsValue({
      app: selectedOneclick.app,
      dbname: selectedOneclick.dbname,
      client_id: selectedOneclick.client_id,
      fqdn: selectedOneclick.fqdn,
      instanceid: selectedOneclick.instanceid,
      password: selectedOneclick.password,
      username: selectedOneclick.username,
      websitename: selectedOneclick.websitename,
      isactive: selectedOneclick.isactive,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }
  useEffect(() => {
    if (oneclickState.drawer) {
      dispatch(activeAllClient());
    }
  }, [clientState.drawer]);
  const handlePortfolio = () => {
    form.validateFields().then((values) => {
      values.isactive = _.isNil(values.isactive) || !values.isactive ? false : true;

      if (selectedOneclick) {
        const { oneclick_id } = selectedOneclick;
        dispatch(editOneclick({ oneclick_id, ...values }));
      } else {
        dispatch(addOneclick(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => toggelOneclickDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add OneClick
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedOneclick ? `Edit OneClick` : `Add OneClick`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggelOneclickDrawer()}
        visible={oneclickState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggelOneclickDrawer()}
              loading={oneclickState.loading}
              disabled={oneclickState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handlePortfolio()}
              loading={false}
              disabled={oneclickState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedOneclick ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={clientState.loading && oneclickState.loading}
          active={clientState.loading && oneclickState.loading}
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="client_id"
                  label="Select Client"
                  initialValue={selectedOneclick?.client_id}
                  rules={[
                    {
                      required: false,
                      message: 'Please select client',
                    },
                  ]}
                >
                  <Select placeholder="Select client" loading={clientState.loading}>
                    {clientState.activeallClients.map((cat) => (
                      <Option value={cat.client_id} key={cat.client_id}>
                        {cat.client_name} {cat.last_name} ({cat.client_id})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="instanceid"
                  label="Instance Id"
                  rules={[{ required: false, message: 'Please enter instance id' }]}
                >
                  <Input placeholder="Please enter instance id" disabled={oneclickState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="app"
                  label="App Name"
                  rules={[{ required: false, message: 'Please enter app name' }]}
                >
                  <Input placeholder="Please enter app name" disabled={oneclickState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="dbname"
                  label="Database"
                  rules={[{ required: false, message: 'Please enter database' }]}
                >
                  <Input placeholder="Please enter database" disabled={oneclickState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="fqdn"
                  label="FQDN"
                  rules={[{ required: false, message: 'Please enter fqdn' }]}
                >
                  <Input placeholder="Please enter fqdn" disabled={oneclickState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="websitename"
                  label="Website Name"
                  rules={[{ required: false, message: 'Please enter websitename' }]}
                >
                  <Input placeholder="Please enter websitename" disabled={oneclickState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="username"
                  label="User Name"
                  rules={[{ required: false, message: 'Please enter user name' }]}
                >
                  <Input placeholder="Please enter user name" disabled={oneclickState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: false, message: 'Please enter password' }]}
                >
                  <Input placeholder="Please enter password" disabled={oneclickState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="isactive" label="Status">
                  <Select placeholder="Status" defaultValue={true}>
                    <Option value={true}>Active</Option>
                    <Option value={false}>Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddOneClickDrawer);
