import * as Constant from './constant';

export const initialState = { totalCount: 0, data: [], loading: false, error: false, drawer: false, deleteModal: false, };

const attributeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_ATTRIBUTE:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
      };
    case Constant.LIST_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: action.payload.total,
        data: action.payload.attributes,
        error: false,
      };
    case Constant.LIST_ATTRIBUTE_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };
    case Constant.ADD_ATTRIBUTE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ADD_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.ADD_ATTRIBUTE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.EDIT_ATTRIBUTE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.EDIT_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.EDIT_ATTRIBUTE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.DELETE_ATTRIBUTE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.DELETE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.DELETE_ATTRIBUTE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.TOGGLE_DRAWER:
      return {
        ...state,
        drawer: !state.drawer,
      };
    case Constant.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !state.deleteModal,
      };
    default:
      return state;
  }
};

export default attributeReducer;
