import { Button, Card, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { getUserToken } from "utils/authHeader";

const MultiColorLineChart = () => {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      position: "top",
      formatter: function (val) {
        if (val >= 1000) {
          const valueInK = (val / 1000).toFixed(1) + "k";
          return valueInK;
        }
        return val;
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: ["#8c8c8c"],
        },
        maxWidth: 150,
        overflow: "ellipsis",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        display: "block",
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: ["#8c8c8c"],
        },
        formatter: function (val) {
          if (val >= 1000) {
            const valueInK = (val / 1000).toFixed(1) + "k";
            return valueInK;
          }
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          if (val >= 1000) {
            const valueInK = (val / 1000).toFixed(1) + "k";
            return "$ " + valueInK + " ";
          }
          return "$ " + val + " ";
        },
      },
    },
  });

  const [start_date, setStartDate] = useState(""); // Start date input state
  const [end_date, setEndDate] = useState(""); // End date input state

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUserToken()}`,
      },
    };
    fetch(`${BASE_URL}/invoice/salescount`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log({res:data.data})
        if (data.success) {
          const categories = data.data.sales.map(
            (item) => item.month
          );
          const seriesData = data.data.sales.map((item) =>
            parseFloat(item.sum)
          );

          setSeries([
            {
              name: "Monthly",
              data: seriesData,
            },
          ]);

          setOptions({
            ...options,
            xaxis: {
              categories: categories,
              labels: {
                style: {
                  fontSize: "10px",
                  fontWeight: 600,
                  colors: ["#8c8c8c"],
                },
                maxWidth: 150,
                overflow: "ellipsis",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                display: "block",
              },
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [start_date, end_date]);

  // const handleFilterClick = () => {
  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${getUserToken()}`,
  //     },
  //   };
  //   fetch(
  //     `${BASE_URL}/order/listgraphs/${start_date}/${end_date}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.success) {
  //         const categories = data.graphinclips.inclip.map(
  //           (item) => item.productcatname
  //         );
  //         const seriesData = data.graphinclips.inclip.map((item) =>
  //           parseFloat(item.price.replace("$", "").replace(",", ""))
  //         );

  //         setSeries([
  //           {
  //             name: "Monthly",
  //             data: seriesData,
  //           },
  //         ]);

  //         setOptions({
  //           ...options,
  //           xaxis: {
  //             categories: categories,
  //             labels: {
  //               style: {
  //                 fontSize: "10px",
  //                 fontWeight: 600,
  //                 colors: ["#8c8c8c"],
  //               },
  //               maxWidth: 150,
  //               overflow: "ellipsis",
  //               whiteSpace: "nowrap",
  //               textOverflow: "ellipsis",
  //               display: "block",
  //             },
  //           },
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // };

  return (
    <div className="">
      <Card
        className="monthly-stats"
        title="Monthly Stats"
        // style={{ backgroundColor: "yellow" }}
        // extra={
        //   <div>
        //     <DatePicker
        //       style={{ marginRight: "15px" }}
        //       placeholder="Start Date"
        //       onChange={(date, dateString) => setStartDate(dateString)}
        //     />
        //     <DatePicker
        //       style={{ marginRight: "15px" }}
        //       placeholder="End Date"
        //       onChange={(date, dateString) => setEndDate(dateString)}
        //     />
        //     <Button
        //       type="primary"
        //       style={{ backgroundColor: "#0069d9" }}
        //       onClick={handleFilterClick}
        //     >
        //       Filter
        //     </Button>
        //   </div>
        // }
      >
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
          />
        </div>
      </Card>
    </div>
  );
};

export default MultiColorLineChart;
