
import * as Constant from './constant';

export const addOneclick = (payload) => {
  return {
    type: Constant.ADD_ONECLICK,
    payload,
  };
};

export const addOneclickSuccess = () => {
  return {
    type: Constant.ADD_ONECLICK_SUCCESS,
  };
};

export const addOneclickError = () => {
  return {
    type: Constant.ADD_ONECLICK_ERROR,
  };
};

export const editOneclick = (payload) => {
  return {
    type: Constant.EDIT_ONECLICK,
    payload,
  };
};

export const editOneclickSuccess = () => {
  return {
    type: Constant.EDIT_ONECLICK_SUCCESS,
  };
};

export const editOneclickError = () => {
  return {
    type: Constant.EDIT_ONECLICK_ERROR,
  };
};

export const listOneclick = (payload) => {
  return {
    type: Constant.LIST_ONECLICK,
    payload,
  };
};

export const listOneclickSuccess = (payload) => {
  return {
    type: Constant.LIST_ONECLICK_SUCCESS,
    payload,
  };
};

export const listOneclickError = () => {
  return {
    type: Constant.LIST_ONECLICK_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
