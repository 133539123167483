import React, { useEffect, useState } from 'react';
import { Card, Table, Pagination, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import _ from 'lodash';
import { listOrderCancel, toggleDrawer } from './action';
import AddOrderCancelDrawer from 'components/AddCancelOrderDrawer';
import { CancelTableConfig } from './CancelTableConfig';
import orderReducer, { initialState as orderInitialState } from '../reducer';
import orderSaga from '../saga';

function CancelOrder() {
  useInjectReducer({ key: 'ordercancel', reducer });
  useInjectSaga({ key: 'ordercancel', saga });
  useInjectReducer({ key: 'order', reducer: orderReducer });
  useInjectSaga({ key: 'order', saga: orderSaga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [selectedOrdercancel, setselectedOrdercancel] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const ordercancelState = useSelector((state) => state.ordercancel || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = CancelTableConfig(
      handleSearch,
      handleReset,
      editUserOrdercancel,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listOrderCancel({ page: 1, limit: 10 }));
  }, []);

  const resetPagination = () => setCurrentPage(1);

  const editUserOrdercancel = (orderupdate) => {
    setselectedOrdercancel(orderupdate);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    let filter = { page, limit: pageSize };
    setCurrentPage(page);
    if ((searchedColumn, searchText)) {
      filter = { ...filter, [searchedColumn]: searchText };
    }
    dispatch(listOrderCancel(filter));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys.length <= 0) {
      message.error('Please select to search');
      return;
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    dispatch(listOrderCancel({ page: 1, limit: 10, [dataIndex]: selectedKeys[0] }));
    resetPagination();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(null);
    setSearchedColumn(null);
    dispatch(listOrderCancel({ page: 1, limit: 10 }));
    resetPagination();
  };
  return (
    <>
      <Card
        title="Cancel Order"
        extra={
          <AddOrderCancelDrawer
            initialState={initialState}
            orderInitialState={orderInitialState}
            selectedOrdercancel={selectedOrdercancel}
            resetPagination={resetPagination}
            editUserOrdercancel={editUserOrdercancel}
            roleAddResponsibility={_.includes(authState.userResponsibilities, 'ADD_CANCEL_ORDER')}
          />
        }
      >
        <Table
          columns={columns}
          loading={ordercancelState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={ordercancelState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={ordercancelState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={ordercancelState.loading}
          onChange={onPageChange}
        />
      </Card>
    </>
  );
}

export default CancelOrder;
