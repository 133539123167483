import { memo } from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Unauthorized from "components/Unauthorized";
import _ from "lodash";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userToken = localStorage.getItem("access_token");
  const { tab } = { ...rest };
  const userState = useSelector((state) => state.auth);
  if (_.isNil(userToken)) {
    return <Redirect to="/" />;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (_.isNil(tab)) {
          return <Unauthorized exact={true} {...props} />;
        }
        if (userState.loading) return <></>;
        if (_.isEmpty(userState.userResponsibilities)) return;
        if (!_.includes(userState.userResponsibilities, tab)) {
          return <Redirect to="/unauthorized" />;
        }
        return <Component exact={true} {...props} />;
      }}
    />
  );
};

export default memo(PrivateRoute);
