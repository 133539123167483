import * as Constant from './constant';

export const listClient = (payload) => {
  return {
    type: Constant.LIST_CLIENT,
    payload,
  };
};

export const listClientSuccess = (payload) => {
  return {
    type: Constant.LIST_CLIENT_SUCCESS,
    payload,
  };
};

export const listClientError = () => {
  return {
    type: Constant.LIST_CLIENT_ERROR,
  };
};

export const addClient = (payload) => {
  return {
    type: Constant.ADD_CLIENT,
    payload,
  };
};

export const addClientSuccess = (payload) => {
  return {
    type: Constant.ADD_CLIENT_SUCCESS,
    payload,
  };
};

export const addClientError = () => {
  return {
    type: Constant.ADD_CLIENT_ERROR,
  };
};

export const editClient = (payload) => {
  return {
    type: Constant.EDIT_CLIENT,
    payload,
  };
};

export const editClientSuccess = (payload) => {
  return {
    type: Constant.EDIT_CLIENT_SUCCESS,
    payload,
  };
};

export const editClientError = () => {
  return {
    type: Constant.EDIT_CLIENT_ERROR,
  };
};

export const deleteClient = (payload) => {
  return {
    type: Constant.DELETE_CLIENT,
    payload,
  };
};

export const deleteClientSuccess = (payload) => {
  return {
    type: Constant.DELETE_CLIENT_SUCCESS,
    payload,
  };
};

export const deleteClientError = () => {
  return {
    type: Constant.DELETE_CLIENT_ERROR,
  };
};
export const activeAllClient = () => {
  return {
    type: Constant.ACTIVE_ALL_CLIENT,
  };
};

export const activeAllClientSuccess = (payload) => {
  return {
    type: Constant.ACTIVE_ALL_CLIENT_SUCCESS,
    payload,
  };
};

export const activeAllClientError = () => {
  return {
    type: Constant.ACTIVE_ALL_CLIENT_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};

export const clearClientUserList = () => {
  return {
    type: Constant.CLEAR_CLIENT_USER_LIST,
  };
};

export const listClientUser = (payload) => {
  return {
    type: Constant.LIST_CLIENT_USER,
    payload,
  };
};

export const listClientUserSuccess = (payload) => {
  return {
    type: Constant.LIST_CLIENT_USER_SUCCESS,
    payload,
  };
};

export const listClientUserError = () => {
  return {
    type: Constant.LIST_CLIENT_USER_ERROR,
  };
};

export const addClientUser = (payload) => {
  return {
    type: Constant.ADD_CLIENT_USER,
    payload,
  };
};

export const addClientUserSuccess = (payload) => {
  return {
    type: Constant.ADD_CLIENT_USER_SUCCESS,
    payload,
  };
};

export const addClientUserError = () => {
  return {
    type: Constant.ADD_CLIENT_USER_ERROR,
  };
};

export const editClientUser = (payload) => {
  return {
    type: Constant.EDIT_CLIENT_USER,
    payload,
  };
};

export const editClientUserSuccess = (payload) => {
  return {
    type: Constant.EDIT_CLIENT_USER_SUCCESS,
    payload,
  };
};

export const editClientUserError = () => {
  return {
    type: Constant.EDIT_CLIENT_USER_ERROR,
  };
};

export const deleteClientUser = (payload) => {
  return {
    type: Constant.DELETE_CLIENT_USER,
    payload,
  };
};

export const deleteClientUserSuccess = (payload) => {
  return {
    type: Constant.DELETE_CLIENT_USER_SUCCESS,
    payload,
  };
};

export const deleteClientUserError = () => {
  return {
    type: Constant.DELETE_CLIENT_USER_ERROR,
  };
};


