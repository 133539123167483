
import * as Constant from './constant';

export const addMessage = (payload) => {
  return {
    type: Constant.ADD_MESSAGE,
    payload,
  };
};

export const addMessageSuccess = () => {
  return {
    type: Constant.ADD_MESSAGE_SUCCESS,
  };
};

export const addMessageError = () => {
  return {
    type: Constant.ADD_MESSAGE_ERROR,
  };
};

export const editMessage = (payload) => {
  return {
    type: Constant.EDIT_MESSAGE,
    payload,
  };
};

export const editMessageSuccess = () => {
  return {
    type: Constant.EDIT_MESSAGE_SUCCESS,
  };
};

export const editMessageError = () => {
  return {
    type: Constant.EDIT_MESSAGE_ERROR,
  };
};

export const listMessage = (payload) => {
  return {
    type: Constant.LIST_MESSAGE,
    payload,
  };
};

export const listMessageSuccess = (payload) => {
  return {
    type: Constant.LIST_MESSAGE_SUCCESS,
    payload,
  };
};

export const listMessageError = () => {
  return {
    type: Constant.LIST_MESSAGE_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
